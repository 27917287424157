import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ITitleName } from '@shared/components/header/interfaces';

@Injectable({
	providedIn: 'root',
})
export class HeaderService {
	private readonly title$ = new Subject<ITitleName>();
	private readonly middleText$ = new BehaviorSubject<string>('');

	readonly titleObservable$ = this.title$.asObservable();
	readonly middleTextObservable$ = this.middleText$.asObservable();

	set title(value: ITitleName) {
		this.title$.next(value);
	}

	set middleText(value: string) {
		this.middleText$.next(value);
	}
}
