import { IFrontLine, IFrontLineDto } from '@shared/interfaces';
import { GenericModel } from '@shared/models/generic.model';

export class FrontLineModel extends GenericModel<IFrontLine, IFrontLineDto> implements IFrontLine {
	companyCode: string;
	country: string;

	constructor(frontLine: IFrontLineDto | null = null) {
		super(frontLine, FrontLineModel);
		this.companyCode = frontLine?.companycode || '';
		this.country = frontLine?.country || '';
	}

	dataTransferObject(): IFrontLineDto {
		const frontlineDto: IFrontLineDto = this.getOriginalData();

		frontlineDto.companycode = this.companyCode;
		frontlineDto.country = this.country;

		return frontlineDto;
	}
}
