import {
	booleanAttribute,
	Component,
	HostBinding,
	inject,
	Input,
	numberAttribute,
	OnInit,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from '@shared/services/app.service';
import { TColor } from '@shared/interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { AppStylesService } from '@shared/services/app-styles.service';

type TIconGoogle =
	| 'group'
	| 'file_download'
	| 'file_upload'
	| 'search_off'
	| 'person_off'
	| 'autorenew'
	| 'light_mode'
	| 'dark_mode'
	| 'logout'
	| 'account_circle'
	| 'dns'
	| 'link'
	| 'schedule'
	| 'web_asset'
	| 'delete'
	| 'person_search'
	| 'save'
	| 'delete_forever'
	| 'sync'
	| 'elevator'
	| 'update'
	| 'translate'
	| 'category'
	| 'format_list_bulleted'
	| 'apple'
	| 'android'
	| 'public'
	| 'done'
	| 'mail'
	| 'pie_chart'
	| 'date_range'
	| 'forum'
	| 'open_in_new'
	| 'check_box'
	| 'lock_person'
	| 'check_box_outline_blank'
	| 'person'
	| 'visibility'
	| 'filter_list'
	| 'login'
	| 'adjust'
	| 'key'
	| 'settings_applications'
	| 'error_outline'
	| 'sync_alt'
	| 'first_page';

// new icon -> must be also added to 'iconKONE' array in icon component
type TIconKone =
	| 'settings'
	| 'admins_manage'
	| 'site'
	| 'access_groups'
	| 'edit'
	| 'info'
	| 'chevron_down'
	| 'search'
	| 'more'
	| 'plus'
	| 'close'
	| 'users'
	| 'user'
	| 'arrow_left'
	| 'arrow_right'
	| 'disallow'
	| 'allow'
	| 'chevron_right'
	| 'chevron_left'
	| 'check'
	| 'help'
	| 'user_main'
	| 'user_star_owner'
	| 'user_management_KONE';

export type TIcon = TIconGoogle | TIconKone;

type TIconType = 'png' | 'svg' | 'mat-icon';

@Component({
	standalone: true,
	selector: 'icon',
	template: `
		<div
			class="align-center-center"
			[matTooltip]="tooltip | translate"
			[matTooltipPosition]="tooltipPosition"
			[matBadge]="badge"
			[matBadgeHidden]="badgeHidden"
			[matBadgePosition]="badgePosition"
			[class.disable]="disabled"
			[class.remove-margin]="removeMargin">
			@if (!loading) {
				<!-- KONE ICONS (SVG) -->
				@if (type == 'svg') {
					<mat-icon
						[style.--icon-size]="size + 'px'"
						[style.color]="!disabled && customColor ? customColor : ''"
						[style.transform]="rotate ? 'rotate(' + rotateDeg + 'deg)' : ''"
						[style.padding]="backgroundButton ? backButtonBgPadding + 'px' : ''"
						[style.background-color]="
							disabled && backgroundButton
								? 'var(--disabled-color)'
								: backButtonBgColor
						"
						[style.border-radius]="backButtonBorderRadius ? backButtonBorderRadius : ''"
						[class.rounded]="backgroundButton"
						[color]="color"
						[svgIcon]="name || ''">
					</mat-icon>
				} @else if (type == 'mat-icon') {
					<!-- ANGULAR MATERIAL ICONS (GOOGLE) -->
					<mat-icon
						[style.--icon-size]="size + 'px'"
						[style.color]="!disabled && customColor ? customColor : ''"
						[style.transform]="rotate ? 'rotate(' + rotateDeg + 'deg)' : ''"
						[style.padding]="backgroundButton ? backButtonBgPadding + 'px' : ''"
						[style.background-color]="
							disabled && backgroundButton
								? 'var(--disabled-color)'
								: backButtonBgColor
						"
						[style.border-radius]="backButtonBorderRadius ? backButtonBorderRadius : ''"
						[class.rounded]="backgroundButton"
						[color]="color"
						class="material-icons-outlined">
						{{ name }}
					</mat-icon>
				} @else {
					@if (type == 'png' && name) {
						<img [ngSrc]="src" [width]="size" [height]="size" [alt]="name" />
					} @else {
						Please provide icon name
					}
				}
			} @else {
				<div class="loading">
					<div
						class="item icon"
						[style]="
							'--icon-size: ' +
							(size + (backgroundButton ? backButtonBgPadding * 2 : 0)) +
							'px'
						"></div>
				</div>
			}
		</div>
	`,
	styles: [
		`
			mat-icon {
				transition: all var(--transition-time);
				margin: 0 !important;
			}

			.remove-margin .mat-icon {
				margin: 0 !important;
			}

			.disable .mat-icon {
				pointer-events: none;
				opacity: 0.5;
				color: var(--disabled-color);
			}

			.rounded {
				position: relative;
				cursor: pointer;
			}

			.rounded:before {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background-color: transparent;
				opacity: 0;
				transition: all var(--transition-time);
			}

			.rounded:hover:before {
				background-color: var(--strong-hover-color);
				opacity: 1;
			}

			.mat-icon.mat-delete {
				color: var(--delete-color);
			}

			.mat-icon.mat-alert {
				color: var(--alert-color);
			}

			.mat-icon.mat-green {
				color: var(--green-color);
			}

			.mat-icon.mat-yellow {
				color: var(--yellow-color);
			}

			.mat-icon.mat-excel {
				color: var(--excel-color);
			}
		`,
	],
	imports: [
		CommonModule,
		NgOptimizedImage,
		MatIconModule,
		MatTooltipModule,
		MatBadgeModule,
		TranslateModule,
	],
})
export class IconComponent implements OnInit {
	private appStylesService: AppStylesService = inject(AppStylesService);

	@Input({ required: true }) name: TIcon;
	@Input({ transform: numberAttribute }) size: number;
	@Input() customColor: string; // overwrites color
	@Input() color: TColor;
	@Input({ transform: booleanAttribute }) rotate: boolean;
	@Input({ transform: numberAttribute }) rotateDeg: number;
	@Input({ transform: numberAttribute }) backButtonBgPadding: number = 5;
	@Input() backButtonBgColor: 'var(--hover-color)' | 'var(--primary-color)' | string;
	@Input() backButtonBorderRadius: 'var(--border-radius)' | 'var(--border-radius-btn)' | '50%' =
		'50%';
	@Input() tooltip: string = '';
	@Input() tooltipPosition: 'above' | 'below' = 'below';
	@Input() badge: string = '';
	@Input() badgePosition: 'above' | 'below' = 'below';
	@Input({ transform: booleanAttribute }) badgeHidden: boolean;
	@Input({ transform: booleanAttribute }) disabled: boolean;
	@Input({ transform: booleanAttribute }) removeMargin: boolean;
	@Input() loading: boolean | undefined | null;

	private sanitizer: DomSanitizer = inject(DomSanitizer);
	private iconRegistry: MatIconRegistry = inject(MatIconRegistry);
	private appService: AppService = inject(AppService);
	private iconKONE: TIconKone[] = [
		'settings',
		'admins_manage',
		'site',
		'access_groups',
		'edit',
		'info',
		'chevron_down',
		'search',
		'more',
		'plus',
		'close',
		'users',
		'user',
		'arrow_left',
		'arrow_right',
		'disallow',
		'allow',
		'chevron_right',
		'chevron_left',
		'check',
		'help',
		'user_main',
		'user_star_owner',
		'user_management_KONE',
	];

	backgroundButton: boolean = false;
	dir: string = 'assets/icons/';
	src: string = '';
	type: TIconType;

	ngOnInit() {
		if (!this.name) return;

		if (!this.size) this.size = this.appStylesService.getNumberFromPixels('iconSize');

		this.backgroundButton = !!this.backButtonBgColor;
		this.type = this.iconKONE.includes(this.name as any) ? 'svg' : 'mat-icon';

		this.src = this.dir + this.type + '/' + this.name + '.' + this.type;

		if (this.type == 'svg') {
			if (this.appService.svgIconsLoaded().indexOf(this.name) > -1) return;

			this.iconRegistry.addSvgIcon(
				this.name,
				this.sanitizer.bypassSecurityTrustResourceUrl(this.src),
			);

			// store loaded svg icon -> prevent adding svg
			this.appService.addSvgIconsLoaded([this.name]);
		}
	}

	@HostBinding('class')
	get class() {
		return 'icon';
	}
}
