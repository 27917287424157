import {
	FormHelperGroupModel,
	IBuildOptions,
	TKeyOptions,
} from '@shared/models/form-helper/form-helper-group.model';
import { FormHelperArrayModel } from '@shared/models/form-helper/form-helper-array.model';

type INestedFormGroup<T> = {
	[key in keyof T]: FormHelperGroupModel<T>;
};
type INestedFormArray<T> = {
	[key in keyof T]: FormHelperArrayModel<T>;
};

export class FormHelperModel<TData> {
	nestedFormGroup: INestedFormGroup<TData> = {} as INestedFormGroup<TData>;
	nestedFormArray: INestedFormArray<TData> = {} as INestedFormArray<TData>;
	errorState = { incorrect: true };

	getForm(value: any, propOptions?: TKeyOptions<TData>, options?: IBuildOptions) {
		let form: any = [];

		if (!value) return form;

		Object.keys(value).forEach(key => {
			let dataValue = value[key];
			const prop = key as keyof TData;
			const hasModel: boolean = propOptions?.[prop]?.hasModel || false;

			if (Array.isArray(dataValue)) {
				if (!hasModel && dataValue.every((item: any) => typeof item === 'string')) {
					dataValue = [dataValue];
				} else if (!hasModel && dataValue.every((item: any) => typeof item === 'number')) {
					dataValue = [dataValue];
				} else {
					this.nestedFormArray[prop] = new FormHelperArrayModel<TData>();
					this.nestedFormArray[prop].build(dataValue);
					dataValue = this.nestedFormArray[prop].form;
				}
			} else if (typeof dataValue === 'object') {
				this.nestedFormGroup[prop] = new FormHelperGroupModel<TData>();
				this.nestedFormGroup[prop].build(dataValue);
				dataValue = this.nestedFormGroup[prop].form;
			} else {
				dataValue = [dataValue];
			}

			if (!options?.isArray) form[prop] = dataValue;
			else form.unshift(dataValue);
		});

		return form;
	}
}
