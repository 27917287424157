<div class="center-content">
	<custom-card class="flex-column align-start-center main" glass-effect>
		<auth-header />

		<icon name="user" size="100" />

		<div
			class="text-uppercase text-bold font-size-super-lg align-center-center item-margin-bottom">
			{{ 'acm_unauthorized_user' | translate }}
		</div>

		<custom-card class="break-spaces" overflow-unset padding-small glass-effect white-background
			>{{ 'acm_unauthorized_user_msg' | translate }}
		</custom-card>

		<custom-button
			class="item-margin-top"
			type="flat"
			label="acm_go_to_login"
			color="primary"
			fill
			(click)="redirectToLogin()" />
	</custom-card>
</div>
