import { Component, HostListener, inject } from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { MatRipple } from '@angular/material/core';
import { CustomDialogDirective } from '@shared/components/custom-dialog/directives';

@Component({
	standalone: true,
	selector: 'user-picture-profile',
	template: ` <icon name="user" /> `,
	styles: [
		`
			:host {
				background: var(--primary-layer-color);
				padding-inline: var(--container-padding-small);
				border-radius: 25px;
				height: calc(var(--button-height) + 4px);
				cursor: pointer;
				overflow: hidden;
				position: relative;

				display: flex;
				align-items: center;
				place-content: center;
			}
		`,
	],
	imports: [IconComponent, CustomDialogDirective],
	providers: [MatRipple],
	hostDirectives: [
		{
			directive: CustomDialogDirective,
			inputs: ['dialogComponent'],
		},
	],
})
export class UserPictureProfileComponent {
	private ripple: MatRipple = inject(MatRipple);

	@HostListener('mousedown', ['$event'])
	onmousedown(event: any) {
		this.doRipple(event);
	}

	@HostListener('touchstart', ['$event'])
	touchstart(event: any) {
		this.doRipple(event);
	}

	doRipple(event: any): void {
		this.ripple.launch(event.x, event.y);
	}
}
