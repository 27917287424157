import { Component, inject, Signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CustomListSelectorComponent } from '@shared/components/custom-list-selector/custom-list-selector.component';
import { Observable } from 'rxjs';
import { SitesService } from '@shared/services/sites.service';
import { AsyncPipe, CommonModule } from '@angular/common';
import { AppRouteManagementService } from '@shared/services/app-route-management.service';
import { Router } from '@angular/router';
import { FrontlinesCountService } from '@shared/services/frontlines-count.service';
import { SiteModel } from '@shared/models/';

@Component({
	standalone: true,
	selector: 'sites-selector',
	template: `
		@if (showSelector) {
			@if (!sitesService.multiple()) {
				<custom-list-selector
					class="item-margin-right display-block"
					[itemsList]="sites$ | async"
					[selectedItem]="selectedSite$ | async"
					[disable]="disableSelector"
					[loading]="loading()"
					[extendSearch]="['apf']"
					(selectedItemChange)="selectSite($event)"
					bgColor="primary"
					inputIcon="site"
					translationKey="name"
					hideErrorSection />
			} @else {
				<custom-list-selector
					class="item-margin-right display-block"
					[itemsList]="sitesService.filteredSites()"
					[selectedItems]="selectedSites()"
					[disable]="disableSelector"
					[loading]="loading()"
					[extendSearch]="['apf']"
					(selectedItemsChange)="selectSites($event)"
					bgColor="primary"
					inputIcon="site"
					translationKey="name"
					multiple
					hideErrorSection />
			}
		}
	`,
	styles: [``],
	imports: [CommonModule, TranslateModule, CustomListSelectorComponent, AsyncPipe],
})
export class SitesSelectorComponent {
	public sitesService: SitesService = inject(SitesService);
	private frontlinesCountService: FrontlinesCountService = inject(FrontlinesCountService);
	private routeManagement: AppRouteManagementService = inject(AppRouteManagementService);
	public router: Router = inject(Router);

	sites$: Observable<SiteModel[]> = this.sitesService.sitesObservable$;
	selectedSite$: Observable<SiteModel | null> = this.sitesService.selectedSiteObservable$;

	loading: Signal<boolean> = this.sitesService.loading;
	selectedSites: Signal<SiteModel[]> = this.sitesService.selectedSites;

	selectSite(site: SiteModel): void {
		this.sitesService.selectedSite = site;
	}

	selectSites(sites: SiteModel[]): void {
		this.sitesService.setSelectedSites(sites);
		this.frontlinesCountService.setSelectedFrontLineSites();
	}

	get showSelector(): boolean {
		const words: string[] = this.router.url.split('/').filter(w => !!w);
		const allowedPaths: string[] = [
			this.routeManagement.getPath('users'),
			this.routeManagement.getPath('accessgroups'),
			this.routeManagement.getPath('sitedetails'),
			this.routeManagement.getPath('statistics'),
			this.routeManagement.getPath('messages'),
		];

		return !!allowedPaths.find(path => this.isItemInUrl(words, path));
	}

	get disableSelector(): boolean {
		const notAllowedPaths: string[] = [
			this.routeManagement.getPath('users') + '/',
			this.routeManagement.getPath('accessgroups') + '/',
		];

		return !!notAllowedPaths.find(path => this.router.url.includes(path));
	}

	isItemInUrl(words: string[], itemUrl: string): boolean {
		for (let word of words) {
			const regex = new RegExp(`\\b${word}\\b`, 'i');
			if (regex.test(itemUrl)) return true;
		}
		return false;
	}
}
