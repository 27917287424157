import {
	ICallType,
	ICallTypeDto,
	ICallTypeLanguage,
	ICallTypeLanguageDto,
	ICallTypeOption,
	ICallTypeOptionDto,
	IClientPlatform,
	IClientPlatformDto,
	IConfiguration,
	IConfigurationDto,
	ILanguage,
	IUiLanguageDto,
} from '@shared/interfaces';
import { GenericObjectModel } from '@shared/models/generic.model';

export class ConfigurationModel implements IConfiguration {
	callTypes: CallTypeOptionModel;
	clientPlatforms: ClientPlatformModel[];
	languages: LanguageModel[];

	private readonly _original: IConfigurationDto | null;

	constructor(configuration: IConfigurationDto | null = null) {
		this.callTypes = new CallTypeOptionModel(configuration?.callTypes);

		this.clientPlatforms =
			configuration?.client_platforms.map(cp => {
				return new ClientPlatformModel(cp);
			}) || [];

		this.languages =
			configuration?.ui_languages.map(l => {
				return new LanguageModel(l);
			}) || [];

		this._original = configuration;
	}

	dataTransferObject(): IConfigurationDto {
		const configurationDto: IConfigurationDto = Object.assign({}, this._original);

		configurationDto.callTypes = this.callTypes.dataTransferObject();

		configurationDto.client_platforms = this.clientPlatforms.map(cp => {
			return cp.dataTransferObject();
		});

		configurationDto.ui_languages = this.languages.map(l => {
			return l.dataTransferObject();
		});

		return configurationDto;
	}
}

export class CallTypeModel implements ICallType {
	description: string;
	order: number;
	shortName: string;

	private readonly _original: ICallTypeDto | null;

	constructor(callType: ICallTypeDto | null = null) {
		this.description = callType?.description || '';
		this.order = callType?.order || 0;
		this.shortName = callType?.shortName || '';

		this._original = callType;
	}

	dataTransferObject(): ICallTypeDto {
		return {
			...Object.assign({}, this._original),
			description: this.description,
			order: this.order,
			shortName: this.shortName,
		};
	}
}

export class CallTypeLanguageModel
	extends GenericObjectModel<ICallTypeLanguage, ICallTypeLanguageDto>
	implements ICallTypeLanguage
{
	// @ts-ignore
	[key: string]: CallTypeModel;

	constructor(callTypeLanguage: ICallTypeLanguageDto | null = null) {
		super(callTypeLanguage);
		for (const key in callTypeLanguage) {
			this[key] = new CallTypeModel(callTypeLanguage[key]);
		}
	}
}

export class CallTypeOptionModel
	extends GenericObjectModel<ICallTypeOption, ICallTypeOptionDto>
	implements ICallTypeOption
{
	// @ts-ignore
	[key: string]: CallTypeLanguageModel;

	constructor(callTypeOption: ICallTypeOptionDto | null = null) {
		super(callTypeOption);
		for (const key in callTypeOption) {
			this[key] = new CallTypeLanguageModel(callTypeOption[key]);
		}
	}
}

export class ClientPlatformModel implements IClientPlatform {
	key: string;
	description: string;

	private readonly _original: IClientPlatformDto | null;

	constructor(clientPlatform: IClientPlatformDto | null = null) {
		this.key = clientPlatform?.key || '';
		this.description = clientPlatform?.description || '';

		this._original = clientPlatform;
	}

	dataTransferObject(): IClientPlatformDto {
		const configurationDto: IClientPlatformDto = Object.assign({}, this._original);
		configurationDto.key = this.key;
		configurationDto.description = this.description;
		return configurationDto;
	}
}

export class LanguageModel implements ILanguage {
	key: string;
	description: string;

	private readonly _original: IUiLanguageDto | null;

	constructor(configuration: IUiLanguageDto | null = null) {
		this.key = configuration?.key || '';
		this.description = configuration?.description || '';

		this._original = configuration;
	}

	dataTransferObject(): IUiLanguageDto {
		return {
			...Object.assign({}, this._original),
			key: this.key,
			description: this.description,
		};
	}
}
