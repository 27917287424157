import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
	InMemoryScrollingOptions,
	provideRouter,
	withComponentInputBinding,
	withHashLocation,
	withInMemoryScrolling,
	withViewTransitions,
} from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {
	HTTP_INTERCEPTORS,
	HttpBackend,
	HttpClient,
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HeadersInterceptor } from '@core/interceptor/headers-interceptor';
import { TokenInterceptor } from '@core/interceptor/token-interceptor';
import { environment } from '@env/environment';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { MockDataService } from '@shared/services/mock-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { ToasterComponent } from '@shared/components/toaster';
import { routes } from '@app/app.routes';

const scrollConfig: InMemoryScrollingOptions = {
	scrollPositionRestoration: 'enabled',
	anchorScrolling: 'disabled',
};

export const appConfig: ApplicationConfig = {
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
		provideRouter(
			routes,
			withComponentInputBinding(),
			withViewTransitions(),
			withInMemoryScrolling(scrollConfig),
			withHashLocation(),
		),
		provideHttpClient(withInterceptorsFromDi()),
		provideAnimations(),
		importProvidersFrom(
			TranslateModule.forRoot({
				defaultLanguage: 'en',
				loader: {
					provide: TranslateLoader,
					useFactory: (httpHandler: HttpBackend) => {
						return new TranslateHttpLoader(
							new HttpClient(httpHandler),
							'./assets/i18n/',
						);
					},
					deps: [HttpBackend],
				},
			}),
		),
		importProvidersFrom([
			ToastrModule.forRoot({
				toastComponent: ToasterComponent,
			}),
		]),
		importProvidersFrom([
			environment.mockService
				? HttpClientInMemoryWebApiModule.forRoot(MockDataService, {
						delay: 400,
						passThruUnknownUrl: true,
					})
				: [],
		]),
		MatSnackBar,
		DatePipe,
	],
};
