import { Component, HostBinding, inject, OnDestroy, OnInit, Signal } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from './services/header.service';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HeaderTitlePipe } from '@shared/components/header/pipes/header-title.pipe';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ITitleName } from '@shared/components/header/interfaces';
import { TRouteAccess } from '@shared/models//route-management.model';
import { AppRouteManagementService } from '@shared/services/app-route-management.service';
import { SitesSelectorComponent } from '@shared/components/sites-selector/sites-selector.component';
import { FrontlinesSelectorComponent } from '@shared/components/frontlines-selector/frontlines-selector.component';
import { DateFilterSelectorComponent } from '@shared/components/date-filter-selector';
import { DestroyerComponent } from '@shared/components/destroyer-component/destroyer.component';
import { PageMarginBottom } from '@shared/directives';
import { UserPictureProfileComponent } from '@shared/components/user-picture-profile/user-picture-profile.component';
import { UserProfileDialogComponent } from '@shared/components/user-profile-dialog';
import { DeviceInfoService } from '@shared/services/device-info.service';

@Component({
	standalone: true,
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	imports: [
		CommonModule,
		HeaderTitlePipe,
		MatToolbarModule,
		SitesSelectorComponent,
		FrontlinesSelectorComponent,
		DateFilterSelectorComponent,
		NgOptimizedImage,
		UserPictureProfileComponent,
	],
	hostDirectives: [PageMarginBottom],
})
export class HeaderComponent extends DestroyerComponent implements OnInit, OnDestroy {
	public router: Router = inject(Router);
	private routeManagement: AppRouteManagementService = inject(AppRouteManagementService);
	private header: HeaderService = inject(HeaderService);
	readonly UserProfileDialogComponent = UserProfileDialogComponent;

	titleMap: TRouteAccess = { ...this.routeManagement.routeAccess };
	title: string = '';

	isTablet: Signal<boolean> = inject(DeviceInfoService).isTablet;
	middleText$ = this.header.middleTextObservable$;

	constructor() {
		super();

		this.header.titleObservable$
			.pipe(this.takeUntilDestroyed())
			.subscribe((value: ITitleName) => {
				if (value.titleTKey) this.titleMap[value.page].title = value.titleTKey;
				if (value.subTitleTKey) this.titleMap[value.page].subtitle = value.subTitleTKey;
			});
	}

	ngOnInit(): void {
		this.header.middleText = '';
	}

	@HostBinding('class.header')
	get classes() {
		return true; // track element for some views (e.g. track height)
	}

	@HostBinding('class.tablet')
	get tabletClass() {
		return this.isTablet();
	}
}
