import {
	IGroupList,
	IGroupListDto,
	IHomeFloorList,
	IHomeFloorListDto,
	IInvite,
	IInviteDto,
	IUser,
	IUserDto,
	TUserType,
} from '@shared/interfaces';
import { GenericModel } from '@shared/models/generic.model';
import { TranslationModel } from '@shared/models/building-translation.model';
import { UserPhoneNumberModel } from '@shared/models/user-phone-number.model';

export class UserModel extends GenericModel<IUser, IUserDto> implements IUser {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
	groupList: string[];
	kecmGroupList: UserGroupListModel[];
	homeFloorList: UserHomeFloorListModel[];
	phoneNumber: UserPhoneNumberModel;
	language?: string;
	comment?: string;
	invite?: UserInviteModel;
	type: TUserType;
	subUsers?: UserModel[];

	constructor(userDto?: IUserDto, user?: IUser) {
		super(userDto, UserModel);

		this.id = userDto?.id || user?.id || '';
		this.email = userDto?.email || user?.email || '';
		this.firstName = userDto?.firstName || user?.firstName || '';
		this.lastName = userDto?.lastName || user?.lastName || '';
		this.groupList = this.getGroupList(userDto?.groupList || user?.groupList);
		this.phoneNumber = new UserPhoneNumberModel(
			userDto?.phoneNumber || user?.phoneNumber.fullNumber,
		);
		this.language = userDto?.language || user?.language || 'en';
		this.comment = userDto?.comment || user?.comment || '';
		this.type = this.getUserType(userDto);
		this.invite = new UserInviteModel(userDto?.invite || user?.invite);
		this.kecmGroupList =
			userDto?.kecmGroupList?.map(gl => new UserGroupListModel(gl)) ||
			user?.kecmGroupList ||
			[];
		this.homeFloorList =
			userDto?.kecmHomeFloorList?.map(hf => new UserHomeFloorListModel(hf)) ||
			user?.homeFloorList ||
			[];

		if (user?.subUsers) this.subUsers = user?.subUsers?.map(u => new UserModel(undefined, u));
		if (userDto?.kecmSubUsers)
			this.subUsers = userDto?.kecmSubUsers?.map(u => new UserModel(u));
	}

	private getGroupList(groupList: string | string[] | undefined): string[] {
		if (typeof groupList == 'string') return groupList.split(',');
		return groupList || [];
	}

	private getUserType(user?: IUserDto): TUserType {
		if (user?.kecmIsCascadeOwner) return 'owner';
		if (user?.kecmIsCascadeMain) return 'main';
		return 'default';
	}

	get fullNumberFormatted(): string {
		return this.phoneNumber.fullNumberFormatted;
	}

	get phoneNumberCode(): string {
		return this.phoneNumber.code;
	}

	get phoneNumberNumber(): string {
		return this.phoneNumber.number;
	}

	dataTransferObject(): IUserDto {
		const userDto: IUserDto = this.getOriginalData();

		userDto.email = this.email;
		userDto.firstName = this.firstName;
		userDto.lastName = this.lastName;
		userDto.type = this.type;
		userDto.comment = this.comment;
		userDto.groupList = this.groupList;
		userDto.phoneNumber = this.phoneNumber.fullNumber;
		userDto.homeFloorList = this.homeFloorList.map(floor => {
			return floor.dataTransferObject().id;
		});

		if (this.subUsers) userDto.kecmSubUsers = this.subUsers.map(u => u.dataTransferObject());

		// if (this.kecmGroupList && this.kecmGroupList.length) {
		//    userDto.kecmGroupList = this.kecmGroupList.map(group => group.dataTransferObject());
		// }

		return userDto;
	}

	dataTransferObjectUpdate(): Partial<IUserDto> {
		const userDto: Partial<IUserDto> = {
			firstName: this.firstName,
			lastName: this.lastName,
			comment: this.comment,
			groupList: this.groupList,
			homeFloorList: this.homeFloorList.map(floor => floor.id),
		};

		if (this.phoneNumber.fullNumber) userDto.phoneNumber = this.phoneNumber.fullNumber;

		// not allowed why ??
		// if (this.kecmGroupList.length) userDto.kecmGroupList = this.kecmGroupList.map(group => {
		//    return group.dataTransferObject()
		// });

		return userDto;
	}
}

export class UserGroupListModel
	extends GenericModel<IGroupList, IGroupListDto>
	implements IGroupList
{
	id: string;
	name: string;

	constructor(groupDto: IGroupListDto | null = null) {
		super(groupDto, UserGroupListModel);

		this.id = groupDto?.id || '';
		this.name = groupDto?.name || '';
	}

	dataTransferObject(): IGroupListDto {
		const groupDto: IGroupListDto = this.getOriginalData();

		groupDto.name = this.name;

		return groupDto;
	}
}

export class UserHomeFloorListModel
	extends GenericModel<IHomeFloorList, IHomeFloorListDto>
	implements IHomeFloorList
{
	id: string;
	shortName: string;
	buildingId: string;
	buildingName: TranslationModel;
	floorName: TranslationModel;

	constructor(homeFloor: IHomeFloorListDto | null = null, shortName: string = '') {
		super(homeFloor, UserHomeFloorListModel);

		this.id = homeFloor?.id || '';
		this.buildingId = homeFloor?.buildingId || '';
		this.shortName = shortName;
		this.floorName = new TranslationModel(homeFloor?.floorName);
		this.buildingName = new TranslationModel(homeFloor?.buildingName);
	}

	dataTransferObject(): IHomeFloorListDto {
		const homeFloorListDto: IHomeFloorListDto = this.getOriginalData();

		homeFloorListDto.floorName = this.floorName.dataTransferObject();
		homeFloorListDto.buildingName = this.buildingName.dataTransferObject();

		return homeFloorListDto;
	}
}

export class UserInviteModel extends GenericModel<IInvite, IInviteDto> implements IInvite {
	invitedForSite: string;
	sentDate: string;

	constructor(inviteDto: IInviteDto | null = null) {
		super(inviteDto, UserInviteModel);

		this.invitedForSite = inviteDto?.invitedForSite || '';
		this.sentDate = inviteDto?.sentDate || '';
	}

	dataTransferObject(): IInviteDto {
		const inviteDto: IInviteDto = this.getOriginalData();

		inviteDto.invitedForSite = this.invitedForSite;
		inviteDto.sentDate = this.sentDate;

		return inviteDto;
	}
}
