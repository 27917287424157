<div class="center-content">
	<custom-card class="flex-column main" glass-effect>
		<auth-header (goBack)="goBack()" showBackButton />

		<div class="item-margin-top flex-column width-100">
			<div class="font-size-lg">{{ 'acm_forgot' | translate }}</div>
			<div class="text-uppercase font-size-super-xl">{{ 'acm_password' | translate }}?</div>
		</div>

		<form
			[formGroup]="formGroup.form"
			(keydown.enter)="forgotPassword()"
			class="item-margin-top width-100">
			<alert-balloon
				[visible]="!!forgotMsg"
				[message]="(forgotMsg | translate) + ' ' + formGroup.getValue('username')"
				(alertClosed)="forgotMsg = ''"
				class="width-100"
				color="success" />

			<!-- CODE -->
			<mat-form-field appearance="outline" class="item-margin-top">
				<mat-label>{{ 'acm_code' | translate }}</mat-label>
				<input
					type="number"
					matInput
					[formControlName]="formGroup.getControlName('code')" />
				@if (formGroup.invalidValue('code')) {
					<mat-error>
						{{ 'acm_field_mandatory' | translate }}
					</mat-error>
				}
			</mat-form-field>

			<div class="item-margin-bottom item-margin-top">
				{{ 'acm_forgot_password_text' | translate }}
			</div>

			<!-- PASSWORD: CONFIRMATION -->
			<password-confirmation (newPassword)="formGroup.setValue('password', $event)" />
		</form>

		<alert-balloon
			[visible]="!!alertMsg"
			[message]="alertMsg"
			[color]="alertColor"
			(alertClosed)="alertMsg = ''"
			class="width-100" />

		@if (!passwordChanged) {
			<custom-button
				class="item-margin-top"
				type="flat"
				label="acm_change_password"
				color="primary"
				fill
				[loadingInside]="loading"
				(click)="forgotPassword()" />
		}
		@if (passwordChanged) {
			<custom-button
				class="item-margin-top"
				type="flat"
				label="acm_go_to_login"
				color="primary"
				fill
				(click)="goBack()" />
		}
	</custom-card>
</div>
