import {
	booleanAttribute,
	Directive,
	EventEmitter,
	HostListener,
	inject,
	Input,
	numberAttribute,
	Output,
	ViewContainerRef,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Directive({
	standalone: true,
	selector: '[custom-dialog]',
	exportAs: 'customdialog',
})
export class CustomDialogDirective {
	@Input({ required: true }) dialogComponent: any;
	@Input() dialogData: any;
	@Input() dialogViewContainerRef: ViewContainerRef;
	@Input({ transform: booleanAttribute }) dialogDisabled: boolean;
	@Input({ transform: booleanAttribute }) dialogEnableClose: boolean;
	@Input({ transform: numberAttribute }) dialogMinWidth: number;
	@Input({ transform: numberAttribute }) dialogMaxWidth: number;

	@Output() dialogClosed: EventEmitter<any> = new EventEmitter<any>();
	@Output() dialogRejected: EventEmitter<void> = new EventEmitter<void>();

	private dialog: MatDialog = inject(MatDialog);

	dialogRef: MatDialogRef<any>;

	@HostListener('click')
	openDialog(): void {
		if (this.dialogDisabled) return;

		this.dialogRef = this.dialog.open(this.dialogComponent, {
			data: this.dialogData,
			disableClose: !this.dialogEnableClose,
			minWidth: this.dialogMinWidth,
			maxWidth: this.dialogMaxWidth,
			autoFocus: false,
			viewContainerRef: this.dialogViewContainerRef,
		});

		this.dialogRef.afterClosed().subscribe(value => {
			if (value) this.dialogClosed.emit(value);
			else this.dialogRejected.emit();
		});
	}
}
