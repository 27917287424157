import { inject, Pipe, PipeTransform } from '@angular/core';
import { IBuildingTranslation } from '@shared/interfaces';
import { IElevator } from '@shared/components/elevators/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { SiteModel } from '@shared/models/';

@Pipe({
	standalone: true,
	name: 'elevatorName',
})
export class ElevatorNamePipe implements PipeTransform {
	private translate: TranslateService = inject(TranslateService);

	transform(
		elevator: IElevator,
		translations: IBuildingTranslation[] | null,
		site: SiteModel | null | undefined,
		skipTranslation?: boolean,
	): string {
		const translation: IBuildingTranslation | undefined = translations?.find(t => {
			return t.buildingId == elevator.fullBuildingId;
		});

		const groupName: string = elevator.isAcs
			? this.translate.instant('acm_group').toLowerCase() + ' ' + elevator.groupId
			: '';
		const name: string | undefined = elevator.buildingName + ' ' + groupName;

		if (!translation) return name;

		const buildingNames = Object.entries(translation.buildingName);
		const siteLang = buildingNames.find(n => n[0] == site?.languageCode);

		if (skipTranslation) return siteLang && siteLang[1] ? siteLang[1] : '';

		const languageText = siteLang
			? `<span class="text-color-soft"> (${siteLang[0]})</span>`
			: '';
		return siteLang && siteLang[1] ? siteLang[1] + languageText : name;
	}
}
