<div mat-dialog-title>
	<div class="flex-row align-start-center height-100">
		<div class="flex-row align-start-center">
			@if (label) {
				<div class="text-bold font-size-super-lg text-uppercase">
					{{ label | translate }}
				</div>
			}
		</div>

		<div class="flex"></div>

		@if (dialogHeaderRight && dialogHeaderRight.template) {
			<ng-container [ngTemplateOutlet]="dialogHeaderRight.template" />
		}

		@if (!hideCloseButton) {
			<icon
				class="align-center-center cursor-pointer"
				name="close"
				customColor="var(--text-color-soft)"
				mat-dialog-close=""
				(click)="rejected.emit()"
				size="28"
				backButtonBgColor="var(--hover-color)" />
		}
	</div>
</div>

@if (hasSearch) {
	<search-input
		[loading]="loading()"
		[(search)]="search"
		(searchChange)="searchChange.emit(search)"
		bgColor="secondary" />
}

@if (dialogAboveContent && dialogAboveContent.template) {
	<ng-container [ngTemplateOutlet]="dialogAboveContent.template" />
}

<mat-dialog-content
	[ngStyle]="dialogContentStyles"
	[class]="dialogContent.dialogClass"
	[class.remove-padding-top]="dialogContent.removePaddingTop">
	<ng-content></ng-content
	><!-- CONTENT INSIDE ELEMENT -->
	<ng-container [ngTemplateOutlet]="dialogContent.template"></ng-container>
</mat-dialog-content>

@if (!hideActions) {
	<mat-dialog-actions>
		@if (dialogActions && dialogActions.template) {
			<ng-container [ngTemplateOutlet]="dialogActions.template" />
		}
		@if (!dialogActions || !dialogActions.template) {
			<custom-button
				type="flat"
				fill
				[label]="actionLabel"
				[color]="actionColor"
				[disabled]="disabledAction"
				(click)="closed.emit()" />
		}
	</mat-dialog-actions>
}
