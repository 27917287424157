import { booleanAttribute, Directive, HostBinding, Input } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[page-margin]',
})
export class PageMargin {
	@HostBinding('class')
	get class() {
		return 'page-margin';
	}
}

@Directive({
	standalone: true,
	selector: '[page-margin-top]',
})
export class PageMarginTop {
	@Input({ alias: 'page-margin-top', transform: booleanAttribute }) state: boolean = true;

	@HostBinding('class')
	get class() {
		return this.state ? 'page-margin-top' : '';
	}
}

@Directive({
	standalone: true,
	selector: '[page-margin-top-half]',
})
export class PageMarginTopHalf {
	@Input({ alias: 'page-margin-top-half', transform: booleanAttribute }) state: boolean = true;

	@HostBinding('class')
	get class() {
		return this.state ? 'page-margin-top-half' : '';
	}
}

@Directive({
	standalone: true,
	selector: '[page-margin-bottom]',
})
export class PageMarginBottom {
	@Input({ alias: 'page-margin-bottom', transform: booleanAttribute }) state: boolean = true;

	@HostBinding('class')
	get class() {
		return this.state ? 'page-margin-bottom' : '';
	}
}

@Directive({
	standalone: true,
	selector: '[page-margin-bottom-half]',
})
export class PageMarginBottomHalf {
	@Input({ alias: 'page-margin-bottom-half', transform: booleanAttribute }) state: boolean = true;

	@HostBinding('class')
	get class() {
		return this.state ? 'page-margin-bottom-half' : '';
	}
}

@Directive({
	standalone: true,
	selector: '[page-margin-left]',
})
export class PageMarginLeft {
	@HostBinding('class')
	get class() {
		return 'page-margin-left';
	}
}

@Directive({
	standalone: true,
	selector: '[page-margin-left-half]',
})
export class PageMarginLeftHalf {
	@HostBinding('class')
	get class() {
		return 'page-margin-left-half';
	}
}

@Directive({
	standalone: true,
	selector: '[page-margin-right]',
})
export class PageMarginRight {
	@HostBinding('class')
	get class() {
		return 'page-margin-right';
	}
}

@Directive({
	standalone: true,
	selector: '[page-margin-right-half]',
})
export class PageMarginRightHalf {
	@HostBinding('class')
	get class() {
		return 'page-margin-right-half';
	}
}
