import {
	booleanAttribute,
	Component,
	computed,
	ContentChild,
	EventEmitter,
	HostBinding,
	Input,
	numberAttribute,
	Output,
	Signal,
	signal,
	WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { IconComponent } from '@shared/components/icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import {
	CustomDialogAboveContentDirective,
	CustomDialogActionsDirective,
	CustomDialogContentDirective,
	CustomDialogHeaderLeftDirective,
	CustomDialogHeaderRightDirective,
} from '@shared/components/custom-dialog/directives';
import { TColor } from '@shared/interfaces';
import { ClickPropagationDirective } from '@shared/directives';
import { SearchInputComponent } from '@shared/components/search-input';
import { CustomButtonComponent } from '@shared/components/custom-button';

@Component({
	standalone: true,
	selector: 'custom-dialog',
	templateUrl: './custom-dialog.component.html',
	styleUrls: ['./custom-dialog.component.scss'],
	imports: [
		CommonModule,
		IconComponent,
		TranslateModule,
		MatDialogModule,
		ClickPropagationDirective,
		SearchInputComponent,
		CustomButtonComponent,
	],
})
export class CustomDialogComponent {
	@Input({ required: true }) label: string;
	@Input({ transform: booleanAttribute }) hideActions: boolean;
	@Input({ transform: booleanAttribute }) hideCloseButton: boolean;
	@Input({ transform: booleanAttribute }) hasSearch: boolean;
	@Input({ transform: numberAttribute }) minWidth: number;
	@Input({ transform: numberAttribute }) maxWidth: number;
	@Input() actionLabel: string;
	@Input() actionColor: TColor = 'primary';
	@Input() disableAction: boolean;
	@Input() search: string;

	@Input('loading')
	set setLoading(loading: boolean) {
		this._loading.set(loading);
	}

	@Input('disableAction')
	set setDisabledAction(disabled: boolean) {
		this._disabledAction.set(disabled);
	}

	@Output() closed: EventEmitter<void> = new EventEmitter<void>();
	@Output() rejected: EventEmitter<void> = new EventEmitter<void>();
	@Output() searchChange: EventEmitter<string> = new EventEmitter<string>();

	@ContentChild(CustomDialogHeaderLeftDirective)
	dialogHeaderLeft: CustomDialogHeaderLeftDirective;
	@ContentChild(CustomDialogHeaderRightDirective)
	dialogHeaderRight: CustomDialogHeaderRightDirective;
	@ContentChild(CustomDialogAboveContentDirective)
	dialogAboveContent: CustomDialogAboveContentDirective;
	@ContentChild(CustomDialogContentDirective) dialogContent: CustomDialogContentDirective;
	@ContentChild(CustomDialogActionsDirective) dialogActions: CustomDialogActionsDirective;

	@HostBinding('style.width')
	get styleMaxWidth() {
		if (!this.minWidth && !this.maxWidth) return '';
		return (
			'clamp(' + (this.minWidth || 0) + 'px, 100vw - 80px, ' + (this.maxWidth || 0) + 'px)'
		);
	}

	private _disabledAction: WritableSignal<boolean> = signal(false);
	private _loading: WritableSignal<boolean> = signal(false);

	loading: Signal<boolean> = this._loading.asReadonly();
	disabledAction: Signal<boolean> = computed(() => {
		return this.loading() || this._disabledAction();
	});

	get dialogContentStyles() {
		const heightOffset: string = this.hasSearch ? '220px' : '180px';
		return {
			'max-height': 'calc(100vh - ' + heightOffset + ')',
		};
	}
}
