import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { Observable, of } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import {
	IBuildingTranslationDto,
	ISyncBuilding,
	ISiteTopologyUpdateDto,
	ISiteDto,
	IBuildingDto,
	IHttpError,
} from '@shared/interfaces';
import { IAuditStatusDto, ISyncSFS } from '@modules/site-details/site-details.models';
import { BuildingTranslationModel } from '@shared/models//building-translation.model';
import { BuildingModel, SiteModel } from '@shared/models/';
import { HttpService } from '@core/http/http.service';
import { ITerminal, IZone } from '@modules/site-details/components/terminals-zones/interfaces';
import { environment } from '@env/environment';

const ENDPOINT = environment.api;

@Injectable({
	providedIn: 'root',
})
export class SitesHttpService {
	private http: HttpClient = inject(HttpClient);
	private httpService: HttpService = inject(HttpService);
	private snackBar: SnackBarService = inject(SnackBarService);

	getSites() {
		return this.http.get<ISiteDto[]>(ENDPOINT + '/sites').pipe(
			map((sites: ISiteDto[]) => {
				try {
					return sites.map(s => new SiteModel(s));
				} catch (e) {
					throw new Error('getSites');
				}
			}),
			catchError(this.handleError('getSites', [])),
		);
	}

	getSiteDetails(siteId: string) {
		return this.http.get<ISiteDto>(ENDPOINT + '/sites/' + siteId).pipe(
			map(site => new SiteModel(site)),
			catchError(this.handleError('', null)),
		);
	}

	getSFSAuditStatus(siteId: string, bookMark: number, type: '' | 'status' | 'trace' = '') {
		const queryType = type ? '?type=' + type : '';
		const symbol: string = queryType ? '&' : '?';
		const queryBookMark = bookMark ? symbol + 'bookmark=' + bookMark : '';

		return this.http
			.get<IAuditStatusDto>(
				ENDPOINT + '/sites/sfsAudit/' + siteId + queryType + queryBookMark,
			)
			.pipe(delay(250), catchError(this.handleError('getSFSAuditStatus', null)));
	}

	getBuilding(buildingId: string | undefined = '', skipError?: boolean) {
		return this.http.get<IBuildingDto>(ENDPOINT + '/sites/topology/' + buildingId).pipe(
			map(building => new BuildingModel(building)),
			catchError(this.handleError(skipError ? '' : 'getBuildingElevator', null)),
		);
	}

	getBuildingTranslations(buildingId: string) {
		return this.http
			.get<IBuildingTranslationDto>(ENDPOINT + '/sites/' + buildingId + '/translation')
			.pipe(
				map(
					buildingTranslation =>
						new BuildingTranslationModel(buildingTranslation, buildingId),
				),
				catchError(this.handleError('', '')),
			);
	}

	syncSFS(siteId: string) {
		return this.http
			.post<ISyncSFS>(ENDPOINT + '/sites/sfsSync/' + siteId, {})
			.pipe(catchError(this.handleError('syncSFS', 'error')));
	}

	syncTopologyAcs(buildingId: string) {
		return this.http
			.post<ISyncBuilding[]>(ENDPOINT + '/sites/' + buildingId + '/topology/acs', {})
			.pipe(catchError(this.handleError('syncTopologyAcs', 'error')));
	}

	syncSite(siteId: string) {
		return this.http
			.post<ISiteTopologyUpdateDto>(ENDPOINT + '/sites/' + siteId + '/topology', {})
			.pipe(
				map(response => response.v2),
				catchError(this.handleError('syncSite', 'error')),
			);
	}

	createSite(site: SiteModel) {
		return this.http.put<ISiteDto>(ENDPOINT + '/sites', site.dataTransferObject()).pipe(
			map(siteDto => new SiteModel(siteDto)),
			catchError(this.handleError('createSite', 'error')),
		);
	}

	deleteSite(id: string, skipError?: boolean) {
		return this.http
			.delete(ENDPOINT + '/sites/' + id)
			.pipe(catchError(this.handleError(skipError ? '' : 'deleteSite', 'error')));
	}

	updateSite(siteId: string, site: SiteModel) {
		return this.http
			.put<ISiteDto>(ENDPOINT + '/sites/' + siteId, site.dataTransferObject())
			.pipe(
				map(siteDto => new SiteModel(siteDto)),
				delay(200),
				catchError(this.handleError('updateSite', 'error')),
			);
	}

	updateBuildingTranslations(siteId: string, buildingTranslation: BuildingTranslationModel) {
		return this.http
			.put(
				ENDPOINT + '/sites/' + siteId + '/translation',
				buildingTranslation.dataTransferObject(),
			)
			.pipe(delay(200), catchError(this.handleError('updateBuildingTranslations', 'error')));
	}

	updateBuildingZones(buildingId: string, zones: IZone[]) {
		return this.http
			.put(ENDPOINT + '/sites/topology/' + buildingId + '/zones', {
				zones: zones,
			})
			.pipe(delay(200), catchError(this.handleError('updateBuildingZones', 'error')));
	}

	updateBuildingTerminals(buildingId: string, terminals: ITerminal[]) {
		return this.http
			.put(ENDPOINT + '/sites/topology/' + buildingId + '/terminals', {
				terminals: terminals,
			})
			.pipe(delay(200), catchError(this.handleError('updateBuildingZones', 'error')));
	}

	handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
		return (error: any): Observable<T> => {
			const message: string = this.httpService.decodeErrorMessage(
				error?.error?.message || '',
			);
			const errorMapped: IHttpError | undefined = this.getErrorMapped(operation, error);

			if (operation == 'syncTopologyAcs') {
				if (errorMapped) result = errorMapped.message as T;
				if (result === 'error')
					this.snackBar.error(message || 'acm_access_synchronization_failed');
			} else {
				if (errorMapped) {
					if (errorMapped.message) this.snackBar.error(message || errorMapped.message);
				} else {
					if (operation) this.snackBar.error('acm_something_went_wrong');
				}
			}

			return of(result as T);
		};
	}

	getErrorMapped(operation: string, error: any): IHttpError | undefined {
		const errorMappings: { [key: string]: any } = {
			syncTopologyAcs: [
				{ status: 403, message: 'forbidden' },
				{ status: 404, message: 'notFound' },
				{ status: 504, message: 'timeout' },
			],
			getSites: '',
			createSite: 'acm_create_site_error',
			deleteSite: 'acm_delete_site_error',
			updateSite: 'acm_update_site_error',
			syncSite: 'acm_sync_site_error',
			syncSFS: 'acm_sync_sfs_error',
			getBuildingElevator: '',
			getSFSAuditStatus: '',
			updateBuildingTranslations: 'acm_update_building_translations_error',
			updateBuildingZones: 'acm_update_terminal_and_zones_error',
			updateBuildingTerminals: 'acm_update_terminal_and_zones_error',
			default: 'acm_something_went_wrong',
		};

		if (errorMappings.hasOwnProperty(operation)) {
			if (Array.isArray(errorMappings[operation])) {
				return errorMappings[operation].find((type: any) => type.status === error.status);
			} else {
				return {
					status: error.status,
					message: errorMappings[operation],
				};
			}
		}

		return undefined;
	}
}
