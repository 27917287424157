import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';
import me from '@assets/mocks/me.json';
import sites from '@assets/mocks/sites.json';
import settings from '@assets/mocks/settings.json';
import settings_force_update from '@assets/mocks/settings_force_update.json';
import settings_time_zones from '@assets/mocks/settings_time_zones.json';
import help from '@assets/mocks/help.json';
import integration from '@assets/mocks/integration.json';
import frontlines from '@assets/mocks/frontlines.json';
import users_management from '@assets/mocks/users_management.json';
import peopleFlowSiteUsers from '@assets/mocks/people_flow_site_users.json';
import site_61d56a3647f4b8001302d414 from '@assets/mocks/site_61d56a3647f4b8001302d414.json';
import topology_3bMWeiBybd_1 from '@assets/mocks/3bMWeiBybd_1.json';
import translation_3bMWeiBybd_1 from '@assets/mocks/translation_3bMWeiBybd_1.json';
import topology_3bMWeiBybd from '@assets/mocks/3bMWeiBybd.json';
import topology_L1QinntdEOg from '@assets/mocks/L1QinntdEOg.json';
import sites_translation_3bMWeiBybd from '@assets/mocks/translations_3bMWeiBybd.json';
import sites_translation_L1QinntdEOg from '@assets/mocks/translations_L1QinntdEOg.json';
import group_64bed5dd0bed2a79d25c5c48 from '@assets/mocks/group_64bed5dd0bed2a79d25c5c48.json';
import group_chowringhee_road from '@assets/mocks/group_chowringhee_road.json';
import site_group_6182613f02aa49001395e8a1 from '@assets/mocks/site_group_6182613f02aa49001395e8a1.json';
import group_users_64bed5dd0bed2a79d25c5c48 from '@assets/mocks/group_users_64bed5dd0bed2a79d25c5c48.json';

@Injectable({
	providedIn: 'root',
})
export class MockDataService implements InMemoryDbService {
	dynamicRoutes: any = {};

	createDb() {
		this.dynamicRoutes[`/api/me`] = me;
		this.dynamicRoutes[`/api/sites`] = sites;
		this.dynamicRoutes[`/api/settings/`] = settings;
		this.dynamicRoutes[`/api/settings`] = settings;
		this.dynamicRoutes[`/api/help/`] = help;
		this.dynamicRoutes[`/api/settings/?key=/force/update?all=true`] = settings_force_update;
		this.dynamicRoutes[`/api/settings/?key=/time/zone?all=true`] = settings_time_zones;
		this.dynamicRoutes[`/api/integration/`] = integration;
		this.dynamicRoutes[`/api/frontlines`] = frontlines;
		this.dynamicRoutes[`/api/manage/users`] = users_management;

		this.dynamicRoutes[`/api/sites/61d56a3647f4b8001302d414`] = site_61d56a3647f4b8001302d414;
		this.dynamicRoutes[`/api/sites/topology/3bMWeiBybd:1`] = topology_3bMWeiBybd_1;
		this.dynamicRoutes[`/api/sites/3bMWeiBybd:1/translation`] = translation_3bMWeiBybd_1;
		this.dynamicRoutes[`/api/sites/topology/3bMWeiBybd`] = topology_3bMWeiBybd;
		this.dynamicRoutes[`/api/sites/topology/L1QinntdEOg`] = topology_L1QinntdEOg;
		this.dynamicRoutes[`/api/sites/3bMWeiBybd/translation`] = sites_translation_3bMWeiBybd;
		this.dynamicRoutes[`/api/sites/L1QinntdEOg/translation`] = sites_translation_L1QinntdEOg;
		this.dynamicRoutes[`/api/groups/64bed5dd0bed2a79d25c5c48`] = group_64bed5dd0bed2a79d25c5c48;
		this.dynamicRoutes[`/api/groups/chowringhee_road`] = group_chowringhee_road;
		this.dynamicRoutes[`/api/group/64bed5dd0bed2a79d25c5c48/users`] =
			group_users_64bed5dd0bed2a79d25c5c48;

		if (sites && Array.isArray(sites)) {
			for (const site of sites) {
				const siteId = site._id;

				this.dynamicRoutes[`/api/sites/${siteId}`] = site;
				this.dynamicRoutes[`/api/sites/${siteId}/users`] = peopleFlowSiteUsers;
				this.dynamicRoutes[`/api/groups?siteId=${siteId}`] =
					site_group_6182613f02aa49001395e8a1;
				this.dynamicRoutes[`/api/groups?siteId=${siteId}`] =
					site_group_6182613f02aa49001395e8a1;
			}
		}

		return { ...this.dynamicRoutes };
	}

	get(reqInfo: any) {
		if (['assets', 'icons'].indexOf(reqInfo.collectionName) > -1) return undefined;
		console.log(reqInfo.url, this.dynamicRoutes[reqInfo.url]);
		if (this.dynamicRoutes[reqInfo.url]) {
			return reqInfo.utils.createResponse$(() => {
				return {
					body: this.dynamicRoutes[reqInfo.url],
					status: 200,
				};
			});
		}

		return undefined; // Continue processing other requests
	}
}
