import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class AppStorageService {
	setItem(storageName: string, item: any) {
		localStorage.setItem(storageName, JSON.stringify(item));
	}

	getItem(storageName: string) {
		return JSON.parse(<string>localStorage.getItem(storageName));
	}

	removeItem(storageName: string) {
		localStorage.removeItem(storageName);
	}
}
