import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { EventManager } from '@angular/platform-browser';
import { AppTools } from '@app/shared/services/app-tools.service';

export interface IKeyEvent {
	element: any;
	description: string;
	keys: string;
	keyText: string[] | [];
	keyPlus: string;
	oneKey: boolean | false;
}

@Injectable({
	providedIn: 'root',
})
export class HotkeysService {
	hotkeys: Array<IKeyEvent> = [];
	defaults: Partial<IKeyEvent> = {
		element: this.document,
	};

	constructor(
		private eventManager: EventManager,
		@Inject(DOCUMENT) private document: Document,
	) {
		// document.body.addEventListener("keydown", function (event) {
		//   console.log(event)
		//   console.log(event.key)
		// });
	}

	addShortcut(options: Partial<IKeyEvent>) {
		// Add 'command' key for macOS when plus key is an arrow (ex. 'meta.ArrowRight')
		if (options.keyPlus?.includes('Arrow') && !AppTools.OS.isWindows()) {
			options.keyPlus = 'meta.' + options.keyPlus;
		}

		if (!options.keys && !options.oneKey) options.keys = this.getKeys(options.keyPlus);
		if (!options.keyText)
			options.keyText = this.getKeyText(options.keyPlus, options.keys, options.oneKey);

		const merged = { ...this.defaults, ...options };
		const event = `keydown.${merged.keys}`;

		this.hotkeys.push(<IKeyEvent>merged);

		return new Observable(observer => {
			const handler = (e: any) => {
				e.preventDefault();
				observer.next(e);
			};

			const dispose = this.eventManager.addEventListener(merged.element, event, handler);

			return () => {
				dispose();
				for (let i = 0; i < this.hotkeys.length; i++) {
					if (this.hotkeys[i].keys === merged.keys) this.hotkeys.splice(i, 1);
				}
			};
		});
	}

	getKeys(keyPlus: string | undefined): string {
		return AppTools.OS.isWindows() ? 'Alt.' + keyPlus : 'Control.' + keyPlus;
	}

	getKeyText(keyPlus: string | undefined, keys: string | undefined, oneKey: undefined | boolean) {
		if (oneKey) return [keys + ''];
		else if (AppTools.OS.isWindows()) {
			return ['ALT', keyPlus + ''];
		} else {
			if (keyPlus?.includes('meta')) {
				let last = keyPlus?.split('.')[1];
				return ['CTRL', 'meta', last + ''];
			} else return ['CTRL', keyPlus + ''];
		}
	}

	getHotKeys() {
		return this.hotkeys;
	}
}
