import { inject, Pipe, PipeTransform } from '@angular/core';
import { TTitleType } from '@shared/components/header/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { TPageType } from '@shared/models//route-management.model';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';

@Pipe({
	standalone: true,
	name: 'headerTitlePipe',
})
export class HeaderTitlePipe implements PipeTransform {
	private translate: TranslateService = inject(TranslateService);
	private titleService: Title = inject(Title);

	transform(url: string, titleMap: any, type: TTitleType, refreshHeader: number = 0): string {
		let pageType: TPageType = url.split('/')[1] as TPageType;

		if (pageType.includes('?')) pageType = pageType.split('?')[0] as TPageType;

		const title: string =
			titleMap[pageType] && titleMap[pageType][type]
				? this.translate.instant(titleMap[pageType][type])
				: '';

		if (title) this.titleService.setTitle(title + ' / ' + environment.name);
		else this.titleService.setTitle(environment.name);

		return title;
	}
}
