import { inject, Injectable } from '@angular/core';
import { UserStoreService } from '@shared/services/user-store.service';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { AppRouteManagementService } from '@shared/services/app-route-management.service';
import { ThemeService } from '@shared/services/theme.service';
import { IUserDataDto } from '@shared/interfaces';
import { UserDataModel } from '@shared/models';
import { AppService } from '@shared/services/app.service';
import { HttpService } from '@core/http/http.service';
import { AppTools } from '@shared/services/app-tools.service';
import { TranslateService } from '@ngx-translate/core';
import { SitesService } from '@shared/services/sites.service';
import { DeviceInfoService } from '@shared/services/device-info.service';
import { AppStylesService } from '@shared/services/app-styles.service';
import { fetchAuthSession } from 'aws-amplify/auth';

@Injectable({
	providedIn: 'root',
})
export class AppStarterService {
	private http: HttpService = inject(HttpService);
	private userStoreService: UserStoreService = inject(UserStoreService);
	private appService: AppService = inject(AppService);
	private themeService: ThemeService = inject(ThemeService);
	private router: Router = inject(Router);
	private routeManagement: AppRouteManagementService = inject(AppRouteManagementService);
	private translate: TranslateService = inject(TranslateService);
	private sites: SitesService = inject(SitesService);
	private deviceInfoService: DeviceInfoService = inject(DeviceInfoService);
	private appStylesService: AppStylesService = inject(AppStylesService);

	async initServices(): Promise<void> {
		this.themeService.fnInit();
		this.appStylesService.fnInit();
		await this.deviceInfoService.fnInit();
		if (this.deviceInfoService.isTablet()) this.appService.closeMenu();
		await this.validateTranslations();
	}

	// redirect: used after login form -> redirect to main path if no path saved
	initUser(redirect: boolean = false): Promise<boolean> {
		if (!redirect) this.appService.startLoading();
		this.appService.clearError();

		return new Promise(resolve => {
			const initializeUser = () => {
				this.http.getUserData().subscribe({
					next: (user: IUserDataDto) => {
						this.userStoreService.updateUser({
							...new UserDataModel(user),
							isLoggedIn: true,
						});

						if (redirect) {
							const path: string = this.routeManagement.getPath('users');
							this.router.navigate([path]);
						}
						this.appService.stopLoading();

						this.sites.fnInit().then(); // load sites
						resolve(true);
					},
					error: error => {
						if ([403].indexOf(error.status) > -1) {
							this.appService.hasError();
							this.router.navigate(['error/user']).then();
						} else {
							this.appService.logout();
						}

						this.appService.stopLoading();
						resolve(false);
					},
				});
			};

			if (!redirect) {
				fetchAuthSession()
					.then(session => {
						const token = session.tokens?.accessToken.toString();
						if (!token) {
							this.appService.stopLoading();
							resolve(false);
						} else {
							initializeUser();
						}
					})
					.catch(error => {
						this.appService.hasError();
						this.router.navigate(['error/user']).then();
						this.appService.stopLoading();
						resolve(false);
					});
			} else {
				initializeUser();
			}
		});
	}

	async validateTranslations(): Promise<void> {
		await AppTools.timeout(50);
		const translation = await lastValueFrom(this.translate.get('acm_logout'));
		if (!translation || translation.includes('acm_')) await this.validateTranslations();
	}
}
