import { Injectable, OnDestroy } from '@angular/core';
import { MonoTypeOperatorFunction, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class DestroyerService implements OnDestroy {
	private destroySubscriptions$: Subject<void> = new Subject<void>();

	takeUntilDestroyed<T>(): MonoTypeOperatorFunction<T> {
		return takeUntil<T>(this.destroySubscriptions$);
	}

	ngOnDestroy(): void {
		this.destroySubscriptions$.next();
		this.destroySubscriptions$.unsubscribe();
	}
}
