import { booleanAttribute, Directive, HostBinding, Input } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[glass-effect]',
})
export class GlassEffectDirective {
	@Input({ alias: 'white-background', transform: booleanAttribute })
	whiteBackgroundState: boolean;
	@Input({ alias: 'black-background', transform: booleanAttribute }) backBackgroundState: boolean;
	@Input() customBackgroundColor: string;

	private whiteBackgroundColor: string = 'rgba(255, 255, 255, 0.14)';
	private backBackgroundColor: string = 'rgba(0, 0, 0, 0.64)';

	@HostBinding('style')
	get styles() {
		return {
			'background': this.getBackgroundColor(),
			'backdrop-filter': 'blur(12px)',
			'-webkit-backdrop-filter': ' blur(12px)',
			'border': '1px solid ' + this.whiteBackgroundColor,
		};
	}

	getBackgroundColor(): string {
		if (this.customBackgroundColor) return this.customBackgroundColor;
		if (this.whiteBackgroundState) return this.whiteBackgroundColor;
		if (this.backBackgroundState) return this.backBackgroundColor;
		return 'transparent';
	}
}
