import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppRouteManagementService, AppService } from '@shared/services';
import { inject } from '@angular/core';

export const ErrorGuard: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => boolean = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
) => {
	const appService: AppService = inject(AppService);
	const router = inject(Router);
	const routeManagement: AppRouteManagementService = inject(AppRouteManagementService);
	const currentPath = state.url.split('/')[1];

	if (currentPath == 'error' && !appService.error()) {
		router.navigate([routeManagement.defaultRoute]).then();
		return false;
	}

	return true;
};
