import { effect, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { UserStoreService } from '@shared/services/user-store.service';
import { AppStorageService } from '@shared/services/app-storage.service';
import { environment } from '@env/environment';
import { LocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { ThemeService } from '@shared/services/theme.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { signOut } from 'aws-amplify/auth';

@Injectable({
	providedIn: 'root',
})
export class AppService {
	private userStoreService: UserStoreService = inject(UserStoreService);
	private appStorage: AppStorageService = inject(AppStorageService);
	private themeService: ThemeService = inject(ThemeService);
	private location: LocationStrategy = inject(LocationStrategy);
	private router: Router = inject(Router);

	private _menuState: WritableSignal<boolean> = signal(true);
	private _loading: WritableSignal<boolean> = signal(true);
	private _error: WritableSignal<boolean> = signal(true);
	private _svgIconsLoaded: WritableSignal<string[]> = signal([]);

	menuState: Signal<boolean> = this._menuState.asReadonly();
	loading: Signal<boolean> = this._loading.asReadonly();
	error: Signal<boolean> = this._error.asReadonly();
	svgIconsLoaded: Signal<string[]> = this._svgIconsLoaded.asReadonly();

	readonly loadingObservable$: Observable<boolean> = toObservable(this.loading);

	constructor() {
		effect(
			() => {
				this.setImageBackground();
			},
			{
				allowSignalWrites: true,
			},
		);
	}

	addSvgIconsLoaded(value: string[]): void {
		const icons: Set<string> = new Set([...this.svgIconsLoaded(), ...value]);
		this._svgIconsLoaded.set([...icons]);
	}

	startLoading(): void {
		this._loading.set(true);
	}

	stopLoading(): void {
		this._loading.set(false);
	}

	hasError(): void {
		this._error.set(true);
	}

	clearError(): void {
		this._error.set(false);
	}

	openMenu(): void {
		this._menuState.set(true);
	}

	closeMenu(): void {
		this._menuState.set(false);
	}

	logout(): void {
		this.userStoreService.clearUser();
		this.clearError();

		const path: string = this.location.path();

		if (
			path !== '/logout' &&
			!path.includes('auth') &&
			!path.includes('error') &&
			path !== '/'
		) {
			this.appStorage.setItem(environment.localStorageSuffix + '_LATEST_URL', path);
		}

		// window.location.replace('logout')
		signOut();

		const authPath: string = 'auth/signin';
		const authForgotPath: string = 'auth/forgot-password';
		if (
			this.router.url == '/' ||
			authPath != this.router.url ||
			authForgotPath != this.router.url
		) {
			this.router.navigate([authPath]).then();
		}
		this.setImageBackground();
	}

	changePassword(): void {
		this.userStoreService.clearUser();
		this.clearError();

		const path: string = this.location.path();
		this.appStorage.setItem(environment.localStorageSuffix + '_LATEST_URL', path);
		this.router.navigate(['auth/change-password']).then();
		this.setImageBackground();
	}

	setImageBackground(): void {
		if ((this.error() || !this.userStoreService.isLoggedIn()) && !this.loading()) {
			this.bodyEl.classList.add('background-image');
			this.bodyEl.classList.add('dark-theme'); // login screen we set dark theme as persistent theme
		} else {
			this.bodyEl.classList.remove('background-image');
			this.themeService.applyTheme(this.themeService.theme()); // set theme to current selected
		}
	}

	get bodyEl(): HTMLElement {
		return document.getElementById('body') as HTMLElement;
	}
}
