import { Injector } from '@angular/core';
import { Routes } from '@angular/router';
import { TRouteAccess } from '@shared/models//route-management.model';
import { AppRouteManagementService } from '@shared/services/app-route-management.service';
import { AuthGuard } from '@core/guards/auth.guard';
import { ErrorGuard } from '@core/guards/error.guard';
import {
	ChangePasswordComponent,
	ForgotPasswordComponent,
	SignInComponent,
} from '@app/pages/auth/components';
import { ErrorComponent } from '@app/pages/error/error.component';
import { SitesUnavailableComponent, UserUnauthorizedComponent } from '@app/pages/error/components';

const injector = Injector.create({
	providers: [{ provide: AppRouteManagementService, useClass: AppRouteManagementService }],
});
const routeManagement = injector.get(AppRouteManagementService);
const routeAccess: TRouteAccess = routeManagement.routeAccess;

export const routes: Routes = [
	{ path: '', redirectTo: 'users', pathMatch: 'full' },
	{
		path: routeAccess['users'].pathConfig.path,
		loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
		canActivate: routeAccess['users'].canActivate,
		data: { animation: 'UserPage' },
	},
	{
		path: routeAccess['sitedetails'].pathConfig.path,
		loadChildren: () =>
			import('./modules/site-details/site-details.module').then(m => m.SiteDetailsModule),
		canActivate: routeAccess['sitedetails'].canActivate,
	},
	{
		path: routeAccess['accessgroups'].pathConfig.path,
		loadChildren: () =>
			import('./modules/access-groups/access-groups.module').then(m => m.AccessGroupsModule),
		canActivate: routeAccess['accessgroups'].canActivate,
		data: { animation: 'AccessGroup' },
	},
	{
		path: routeAccess['configurations'].pathConfig.path,
		loadChildren: () =>
			import('./modules/configurations/configurations.module').then(
				m => m.ConfigurationsModule,
			),
		canActivate: routeAccess['configurations'].canActivate,
	},
	{
		path: routeAccess['usersmanagement'].pathConfig.path,
		loadChildren: () =>
			import('./modules/user-management/user-management.module').then(
				m => m.UserManagementModule,
			),
		canActivate: routeAccess['usersmanagement'].canActivate,
		data: { animation: 'UsersManagement' },
	},
	{
		path: routeAccess['statistics'].pathConfig.path,
		loadChildren: () =>
			import('./modules/statistics/statistics.module').then(m => m.StatisticsModule),
		canActivate: routeAccess['statistics'].canActivate,
	},
	{
		path: routeAccess['messages'].pathConfig.path,
		loadChildren: () =>
			import('./modules/messages/messages.module').then(m => m.MessagesModule),
		canActivate: routeAccess['messages'].canActivate,
	},
	{
		path: routeAccess['help'].pathConfig.path,
		loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule),
		canActivate: routeAccess['help'].canActivate,
	},
	{
		path: 'auth',
		canActivate: [AuthGuard],
		children: [
			{
				path: 'signin',
				component: SignInComponent,
				canActivate: [AuthGuard],
			},
			{
				path: 'forgot-password',
				component: ForgotPasswordComponent,
				canActivate: [AuthGuard],
			},
			{
				path: 'forgot-password/:email',
				component: ForgotPasswordComponent,
				canActivate: [AuthGuard],
			},
			{
				path: 'change-password',
				component: ChangePasswordComponent,
				canActivate: [AuthGuard],
			},
		],
	},
	{
		path: 'error',
		component: ErrorComponent,
		canActivate: [ErrorGuard],
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'default',
			},
			{
				path: 'default',
				component: ErrorComponent,
				canActivate: [ErrorGuard],
			},
			{
				path: 'user',
				component: UserUnauthorizedComponent,
				canActivate: [ErrorGuard],
			},
			{
				path: 'sites',
				component: SitesUnavailableComponent,
				canActivate: [ErrorGuard],
			},
		],
	},
	{ path: '**', redirectTo: routeManagement.defaultRoute },
];
