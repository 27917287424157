import { booleanAttribute, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { IconComponent } from '@shared/components/icon/icon.component';
import { MatRippleModule } from '@angular/material/core';

@Component({
	standalone: true,
	selector: 'auth-header',
	template: `
		<div class="flex-row align-start-center width-100 item-margin-bottom">
			@if (!showBackButton) {
				<div class="flex"></div>
			}
			@if (showBackButton) {
				<icon
					(click)="goBack.emit()"
					name="arrow_left"
					size="30"
					matRipple
					backButtonBgColor="var(--hover-color)"
					class="flex align-start-center" />
			}
			<img
				src="assets/images/kone-logo-blue.png"
				loading="lazy"
				alt="KONE"
				[width]="imageWidth"
				[height]="imageWidth / 2.59" />
			<div class="flex"></div>
		</div>
	`,
	styleUrls: ['../../../pages/auth/auth.component.scss'],
	imports: [IconComponent, NgOptimizedImage, MatRippleModule],
})
export class AuthHeaderComponent {
	@Input({ transform: booleanAttribute }) showBackButton: boolean;

	@Output() goBack: EventEmitter<void> = new EventEmitter<void>();

	imageWidth: number = 105;
}
