import { effect, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { TIcon } from '@shared/components/icon/icon.component';
import { AppStorageService } from '@shared/services/app-storage.service';
import { environment } from '@env/environment';
import { AppStylesService } from '@shared/services/app-styles.service';

export type TTheme = 'dark-theme' | 'light-theme';

@Injectable({
	providedIn: 'root',
})
export class ThemeService {
	private overlayContainer: OverlayContainer = inject(OverlayContainer);
	private appStorage: AppStorageService = inject(AppStorageService);
	private appStylesService: AppStylesService = inject(AppStylesService);

	private storageName: string = environment.localStorageSuffix + '_THEME';

	private _theme: WritableSignal<TTheme> = signal('light-theme');
	private _iconTheme: WritableSignal<TIcon> = signal('light_mode');
	private _textTheme: WritableSignal<string> = signal('acm_light_theme');

	theme: Signal<TTheme> = this._theme.asReadonly();
	iconTheme: Signal<TIcon> = this._iconTheme.asReadonly();
	textTheme: Signal<string> = this._textTheme.asReadonly();

	themes: Signal<TTheme[]> = signal(['dark-theme', 'light-theme']);

	constructor() {
		effect(() => {
			this.appStorage.setItem(this.storageName, this.theme());
		});
	}

	fnInit() {
		const theme = this.appStorage.getItem(this.storageName) || 'light-theme';
		this.applyTheme(theme);
	}

	trigger(): void {
		if (this.theme() == 'dark-theme') this.applyTheme('light-theme');
		else this.applyTheme('dark-theme');
	}

	applyTheme(theme: TTheme): void {
		this.applyThemeOnOverlays(theme); // Apply the current theme on components with overlay (e.g. Dropdowns, Dialogs)
		this.applyThemeToBodyApp(theme); // Apply the current theme on body app
		this.applyThemeToHtmlApp(theme); // Apply the current theme on body app

		this._theme.set(theme);

		if (this.theme() === 'dark-theme') {
			this._iconTheme.set('dark_mode');
			this._textTheme.set('acm_dark_theme');
		} else {
			this._iconTheme.set('light_mode');
			this._textTheme.set('acm_light_theme');
		}

		this.applyThemeToMetaTag();
	}

	applyThemeOnOverlays(theme: TTheme): void {
		const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
		if (this.themes().length) overlayContainerClasses.remove(...this.themes());

		overlayContainerClasses.add(theme);
	}

	applyThemeToBodyApp(theme: TTheme): void {
		let body: any = document.getElementById('body');
		const bodyClasses = body.classList;
		if (this.themes().length) bodyClasses.remove(...this.themes());

		body.classList.add(theme);
	}

	applyThemeToHtmlApp(theme: TTheme): void {
		let html: HTMLHtmlElement = document.getElementsByTagName('html')[0];
		const htmlClasses = html.classList;
		if (this.themes().length) htmlClasses.remove(...this.themes());

		html.classList.add(theme);
	}

	applyThemeToMetaTag(): void {
		const themeColorMetaTag: Element | null = document.querySelector(
			'meta[name="theme-color"]',
		);
		if (!themeColorMetaTag) return;
		themeColorMetaTag.setAttribute('content', this.appStylesService.styles().appBackground);
	}
}
