import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { DateRange } from '@angular/material/datepicker';
import { ICountDto, IFrontLineCountDto } from '@shared/interfaces';
import { CountFrontlineModel, CountModel } from '@shared/models/';
import { HttpService } from '@core/http/http.service';
import { environment } from '@env/environment';

const ENDPOINT = environment.api;

@Injectable({
	providedIn: 'root',
})
export class CountsHttpService {
	private http: HttpClient = inject(HttpClient);
	private httpService: HttpService = inject(HttpService);
	private snackBar: SnackBarService = inject(SnackBarService);

	getFrontlineCounts(dateRange: DateRange<Date | null>) {
		const queryDateRange: string =
			dateRange.start && dateRange.end
				? '?datefrom=' +
					new Date(dateRange.start).toISOString() +
					'&dateto=' +
					new Date(dateRange.end).toISOString()
				: '';

		return this.http
			.get<IFrontLineCountDto[]>(ENDPOINT + '/counts/frontline' + queryDateRange)
			.pipe(
				map(countFrontLines => {
					return countFrontLines.map(cf => {
						return new CountFrontlineModel(cf);
					});
				}),
				catchError(this.handleError('getFrontlineCounts', [])),
			);
	}

	getSitesCounts(dateRange: DateRange<Date | null>) {
		const queryDateRange: string =
			dateRange.start && dateRange.end
				? '?datefrom=' +
					new Date(dateRange.start).toISOString() +
					'&dateto=' +
					new Date(dateRange.end).toISOString()
				: '';

		return this.http.get<ICountDto[]>(ENDPOINT + '/counts/sites' + queryDateRange).pipe(
			map(count => {
				return count.map(cf => {
					return new CountModel(cf);
				});
			}),
			catchError(this.handleError('getFrontlineCounts', [])),
		);
	}

	handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			const message: string = this.httpService.decodeErrorMessage(
				error?.error?.message || '',
			);

			if (error.status === 403) this.snackBar.error(message || 'acm_forbidden');
			else if ([500, 503].indexOf(error.status) > -1)
				this.snackBar.error(message || 'acm_service_not_available');
			else if (operation === 'getFrontlineCounts')
				this.snackBar.error(message || 'acm_count_error');
			else this.snackBar.error('acm_something_went_wrong');

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
}
