import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

@Injectable({
	providedIn: 'root',
})
export class SnackBarService {
	private translate: TranslateService = inject(TranslateService);
	private toastr: ToastrService = inject(ToastrService);

	private readonly DEFAULT_DELAY: number = 10000;

	success(tKey: string, delay: number = this.DEFAULT_DELAY): void {
		this.toastr.success(this.translate.instant(tKey), '', {
			...this.config(),
			timeOut: delay,
			// extendedTimeOut: delay,
		});
	}

	warn(tKey: string, delay: number = this.DEFAULT_DELAY): void {
		this.toastr.warning(this.translate.instant(tKey), '', {
			...this.config(),
			timeOut: delay,
		});
	}

	error(tKey: string, delay: number = this.DEFAULT_DELAY): void {
		this.toastr.error(this.translate.instant(tKey), '', {
			...this.config(),
			timeOut: delay,
		});
	}

	info(tKey: string, delay: number = this.DEFAULT_DELAY): void {
		this.toastr.info(this.translate.instant(tKey), '', {
			...this.config(),
			timeOut: delay,
		});
	}

	private config(): Partial<IndividualConfig> {
		return {
			timeOut: 4000,
			extendedTimeOut: 4000,
			tapToDismiss: false,
			closeButton: true,
			progressBar: true,
		};
	}
}
