import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppRouteManagementService, AppService, UserStoreService } from '@shared/services';
import { inject } from '@angular/core';
import { TPageType } from '@shared/models/route-management.model';
import { filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const UserGuard: (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
) => Observable<boolean> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const appService: AppService = inject(AppService);
	const router = inject(Router);
	const routeManagement: AppRouteManagementService = inject(AppRouteManagementService);
	const userData = inject(UserStoreService);
	const currentPath = state.url.split('/')[1] as TPageType;

	return appService.loadingObservable$.pipe(
		filter(loading => !loading),
		map(loading => {
			const activate: boolean = routeManagement.canActivate(
				currentPath,
				state.url,
				userData.user(),
			);

			if (!activate) router.navigate([routeManagement.defaultRoute]).then();

			return activate;
		}),
	);
};
