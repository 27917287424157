import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { from, Observable, switchMap } from 'rxjs';
import { fetchAuthSession } from 'aws-amplify/auth';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return from(fetchAuthSession()).pipe(
			switchMap(session => {
				const token = session.tokens?.accessToken.toString();
				const authReq = request.clone({
					setHeaders: {
						Authorization: `Bearer ${token}`,
					},
				});
				return next.handle(authReq);
			}),
		);
	}
}
