import { inject, Injectable, signal, Signal, WritableSignal } from '@angular/core';
import {
	IInformationDialogData,
	InformationDialogComponent,
} from '@shared/components/information-dialog';
import { MatDialog } from '@angular/material/dialog';
import { IUserType } from '@shared/interfaces';

@Injectable()
export class UsersService {
	private dialog: MatDialog = inject(MatDialog);

	private _userTypes: WritableSignal<IUserType[]> = signal([
		{ type: 'owner', translation: 'acm_owner' },
		{ type: 'main', translation: 'acm_main_user' },
		{ type: 'default', translation: 'acm_default' },
	]);

	userTypes: Signal<IUserType[]> = this._userTypes.asReadonly();

	callGroupsRequiredMessage(): void {
		const data: IInformationDialogData = {
			title: 'acm_call_groups_missing',
			texts: ['acm_call_groups_missing_description'],
		};
		this.dialog.open(InformationDialogComponent, {
			data,
			maxWidth: 380,
		});
	}
}
