import { Component, inject } from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { AppService } from '@shared/services';
import { CustomCardComponent } from '@shared/components/custom-card';
import { AuthHeaderComponent } from '@shared/components/auth-header/auth-header.component';
import { GlassEffectDirective } from '@shared/directives';
import { TranslateModule } from '@ngx-translate/core';
import { CustomButtonComponent } from '@shared/components/custom-button';

@Component({
	standalone: true,
	selector: 'app-user-unauthorized',
	templateUrl: './user-unauthorized.component.html',
	imports: [
		TranslateModule,
		CustomCardComponent,
		AuthHeaderComponent,
		IconComponent,
		GlassEffectDirective,
		CustomButtonComponent,
	],
	styleUrls: ['../../error.component.scss'],
})
export class UserUnauthorizedComponent {
	private appService: AppService = inject(AppService);

	redirectToLogin(): void {
		this.appService.logout();
	}
}
