import { Injectable } from '@angular/core';
import { AuthGuard } from '@core/guards/auth.guard';
import {
	configurationTabs,
	IAvailablePathUserType,
	IRouteAccessConfig,
	siteDetailsTabs,
	TAvailablePath,
	TConfigTab,
	TPageType,
	TRouteAccess,
	userMgmtTabs,
} from '@shared/models//route-management.model';
import { TUserData } from '@shared/interfaces';
import { UserDataModel } from '@shared/models';
import { UserGuard } from '@core/guards/user.guard';

@Injectable({
	providedIn: 'root',
})
export class AppRouteManagementService {
	get routeAccess(): TRouteAccess {
		return {
			users: {
				canActivate: [AuthGuard, UserGuard],
				userTypes: ['globalAdmin', 'facilityManager', 'technical', 'viewer'],
				pathConfig: {
					path: 'users',
				},
				title: 'acm_users',
				subtitle: '',
				icon: 'users',
				visibleOnMenu: true,
			},
			accessgroups: {
				canActivate: [AuthGuard, UserGuard],
				userTypes: ['globalAdmin', 'facilityManager', 'technical', 'viewer'],
				pathConfig: {
					path: 'accessgroups',
				},
				title: 'acm_call_groups',
				subtitle: '',
				icon: 'access_groups',
				visibleOnMenu: true,
			},
			sitedetails: {
				canActivate: [AuthGuard, UserGuard],
				userTypes: ['globalAdmin', 'facilityManager', 'technical', 'viewer'],
				pathConfig: {
					path: 'sitedetails',
					availablePaths: siteDetailsTabs.map(tab => {
						return {
							path: tab,
							userTypes: ['globalAdmin', 'facilityManager', 'technical', 'viewer'],
						};
					}),
				},
				title: 'acm_site_details',
				subtitle: '',
				icon: 'site',
				visibleOnMenu: true,
			},
			configurations: {
				canActivate: [AuthGuard, UserGuard],
				userTypes: ['globalAdmin', 'technical', 'viewer'],
				pathConfig: {
					path: 'configurations',
					availablePaths: configurationTabs
						.filter((tab: TConfigTab) => {
							return tab !== 'faqlinks';
						})
						.map((tab: TConfigTab) => {
							return {
								get path(): TAvailablePath {
									const fqaLinkOptions: TAvailablePath[] = [
										'categories',
										'topics',
									];
									if (fqaLinkOptions.indexOf(tab) > -1)
										return ('faqlinks/' + tab) as TAvailablePath;
									else return tab;
								},
								userTypes:
									tab == 'createsite'
										? ['globalAdmin', 'technical']
										: ['globalAdmin', 'viewer'],
							};
						}),
				},
				title: 'acm_configurations',
				subtitle: '',
				icon: 'settings',
				visibleOnMenu: true,
			},
			usersmanagement: {
				canActivate: [AuthGuard, UserGuard],
				userTypes: ['globalAdmin', 'technical', 'viewer'],
				pathConfig: {
					path: 'usersmanagement',
					availablePaths: userMgmtTabs.map(tab => {
						return {
							path: tab,
							userTypes: ['globalAdmin', 'technical', 'viewer'],
						};
					}),
				},
				title: 'acm_users_management',
				subtitle: '',
				icon: 'group',
				visibleOnMenu: true,
			},
			statistics: {
				canActivate: [AuthGuard, UserGuard],
				userTypes: ['globalAdmin', 'facilityManager', 'technical', 'viewer'],
				pathConfig: {
					path: 'statistics',
				},
				title: 'acm_statistics',
				subtitle: '',
				icon: 'pie_chart',
				visibleOnMenu: true,
			},
			messages: {
				canActivate: [AuthGuard, UserGuard],
				userTypes: ['globalAdmin', 'facilityManager', 'technical', 'viewer'],
				pathConfig: {
					path: 'messages',
				},
				title: 'acm_messages',
				subtitle: '',
				icon: 'forum',
				visibleOnMenu: true,
			},
			help: {
				canActivate: [AuthGuard, UserGuard],
				userTypes: ['globalAdmin', 'facilityManager', 'technical', 'viewer'],
				pathConfig: {
					path: 'help',
				},
				title: 'acm_help',
				subtitle: '',
				icon: 'info',
				visibleOnMenu: false,
			},
		};
	}

	get defaultRoute(): TPageType {
		return 'users';
	}

	getRouteAvailability(config: IRouteAccessConfig, user: UserDataModel): boolean {
		const userType: TUserData = user.role.userType;

		// skip route not visible
		if (!config.visibleOnMenu) return false;

		// default/general routes always should be shown if route config has as router type
		if (!!config.userTypes.find((type: TUserData): boolean => type == userType)) return true;

		return false;
	}

	getPath(mainPath: TPageType): string {
		return '/' + this.routeAccess[mainPath].pathConfig.path;
	}

	canActivate(mainPath: TPageType, url: string, user: UserDataModel): boolean {
		const userType: TUserData = user.role.userType;
		const mainRoute = this.routeAccess[mainPath];
		const canActivate: boolean =
			(mainRoute && !!mainRoute.userTypes.find(type => type == userType)) ||
			!mainRoute.userTypes.length;

		if (canActivate && mainRoute.pathConfig.availablePaths?.length) {
			const pathAvailable: IAvailablePathUserType | undefined =
				mainRoute.pathConfig.availablePaths.find(p => {
					return mainPath + '/' + p.path == url;
				});
			if (pathAvailable) return !!pathAvailable?.userTypes.find(type => type == userType);
		}

		return canActivate;
	}
}
