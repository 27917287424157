import { inject, Pipe, PipeTransform } from '@angular/core';
import { UserHomeFloorListModel } from '@shared/models';
import { SitesService } from '@shared/services/sites.service';

@Pipe({
	standalone: true,
	name: 'homeFloorNameList',
})
export class HomeFloorNameListPipe implements PipeTransform {
	private sitesService: SitesService = inject(SitesService);

	transform(floors: UserHomeFloorListModel[]): string[] {
		const languageCode: string = this.sitesService.selectedSite.languageCode;
		return floors.map(floor => floor.floorName[languageCode] || floor.shortName);
	}
}
