import {
	IBuildingTranslation,
	IBuildingTranslationDto,
	IAreaTranslation,
	IAreaTranslationDto,
	ITranslation,
	ITranslationDto,
} from '@shared/interfaces';
import { GenericModel, GenericObjectModel } from '@shared/models/generic.model';

export class BuildingTranslationModel
	extends GenericModel<IBuildingTranslation, IBuildingTranslationDto>
	implements IBuildingTranslation
{
	_id: string;
	buildingId: string;
	buildingName: TranslationModel;
	areas: AreaTranslationModel;

	constructor(buildingTranslation: IBuildingTranslationDto | null = null, buildingId?: string) {
		super(buildingTranslation, BuildingTranslationModel);
		this._id = buildingTranslation?._id || buildingId || '';
		this.buildingId = buildingTranslation?.buildingId || buildingId || '';
		this.buildingName = new TranslationModel(buildingTranslation?.buildingName);
		this.areas = new AreaTranslationModel(buildingTranslation?.areas);
	}

	dataTransferObject(): IBuildingTranslationDto {
		const buildingTranslationDto: IBuildingTranslationDto = this.getOriginalData();

		buildingTranslationDto._id = this._id;
		buildingTranslationDto.buildingId = this.buildingId;
		buildingTranslationDto.buildingName = this.buildingName.dataTransferObject();
		buildingTranslationDto.areas = this.areas.dataTransferObject();

		return buildingTranslationDto;
	}
}

export class TranslationModel
	extends GenericObjectModel<ITranslation, ITranslationDto>
	implements ITranslation
{
	// @ts-ignore
	[key: string]: string;

	constructor(translation: ITranslationDto | null = null) {
		super(translation);

		for (const key in translation) {
			this[key] = translation[key];
		}
	}
}

export class AreaTranslationModel
	extends GenericObjectModel<IAreaTranslation, IAreaTranslationDto>
	implements IAreaTranslation
{
	// @ts-ignore
	[key: string]: TranslationModel;

	constructor(areaTranslation: IAreaTranslationDto | null = null) {
		super(areaTranslation);

		for (const key in areaTranslation) {
			this[key] = new TranslationModel(areaTranslation[key]);
		}
	}
}
