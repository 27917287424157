import {
	Component,
	EventEmitter,
	Input,
	numberAttribute,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { TColor } from '@shared/interfaces';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	standalone: true,
	selector: 'alert-balloon',
	template: `
		@if (!loading && visible) {
			<div
				class="alert-balloon flex-row align-start-center"
				[ngClass]="color"
				@errorItemAnimation>
				<div class="flex-row pre-line">
					{{ message | translate }}
					@if (messagePlus) {
						<span>&nbsp;{{ messagePlus | translate }}</span>
					}
				</div>
				<div class="flex"></div>
				<div class="align-center-center">
					@if (autoClose > 0) {
						<div class="item-margin-right">{{ remainingSeconds$ | async }}</div>
					}
					<icon
						name="close"
						tooltip="acm_close"
						class="cursor-pointer"
						backButtonBgColor="var(--hover-color)"
						(click)="alertClosed.emit()" />
				</div>
			</div>
		}
		@if (loading) {
			<div class="loading loading-container flex-row align-start-center">
				<div class="item border-radius"></div>
				<div class="flex"></div>
				<div class="item icon"></div>
			</div>
		}
	`,
	styles: [
		`
			.alert-balloon {
				margin-bottom: var(--item-margin);
				background-color: var(--alert-color);
				min-height: 50px;
				color: white;
				border-radius: var(--border-radius);
				padding: var(--container-padding-small);

				&.warn {
					background-color: var(--error-color);
				}

				&.green {
					background-color: var(--green-color);
				}

				&.success {
					background-color: var(--success-color);
				}

				&.primary {
					background-color: var(--primary-color);
				}
			}
		`,
	],
	animations: [
		trigger('errorItemAnimation', [
			transition(':enter', [
				style({
					opacity: 0,
					height: '0px',
					minHeight: '0',
					marginBottom: '0',
					padding: '0',
				}),
				animate(
					'200ms',
					style({
						opacity: 1,
						height: '*',
						minHeight: '*',
						marginBottom: '*',
						padding: '*',
					}),
				),
			]),
			transition(':leave', [
				style({ opacity: 1, height: '*', minHeight: '*', marginBottom: '*', padding: '*' }),
				animate(
					'200ms',
					style({
						opacity: 0,
						height: '0px',
						minHeight: '0',
						marginBottom: '0',
						padding: '0',
					}),
				),
			]),
		]),
	],
	imports: [CommonModule, TranslateModule, IconComponent],
})
export class AlertBalloonComponent implements OnChanges {
	@Input({ required: true }) message: string;
	@Input() messagePlus: string;
	@Input() color: TColor;
	@Input() visible: boolean = true;
	@Input() loading: boolean | null = false;
	@Input({ transform: numberAttribute }) autoClose: number; // auto close alert in seconds

	@Output() alertClosed: EventEmitter<void> = new EventEmitter<void>();

	countdown$: Subject<void> = new Subject<void>();
	remainingSeconds$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	ngOnChanges(changes: SimpleChanges) {
		if (changes['visible']) {
			if (this.visible && this.autoClose > 0) this.startTimer();
			else this.stopTimer();
		}
	}

	startTimer() {
		this.remainingSeconds$.next(this.autoClose);
		interval(1000)
			.pipe(takeUntil(this.countdown$))
			.subscribe(() => {
				this.remainingSeconds$.next(this.remainingSeconds$.getValue() - 1);

				if (this.remainingSeconds$.getValue() === 0) {
					this.alertClosed.emit();
					this.stopTimer();
				}
			});
	}

	stopTimer(): void {
		this.countdown$.next();
	}
}
