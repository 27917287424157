import { Component, inject, Signal } from '@angular/core';
import { CustomListSelectorComponent } from '@shared/components/custom-list-selector/custom-list-selector.component';
import { CommonModule } from '@angular/common';
import { AppRouteManagementService } from '@shared/services/app-route-management.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SitesService } from '@shared/services/sites.service';
import { FrontlinesCountService } from '@shared/services/frontlines-count.service';
import { DateFilterDialogComponent } from '@shared/components/date-filter-selector/components/';
import { DateRange } from '@angular/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateRangeFilterService } from '@shared/services/date-range-filter.service';
import { CustomDialogDirective } from '@shared/components/custom-dialog/directives/custom-dialog.directive';

@Component({
	standalone: true,
	selector: 'date-filter-selector',
	template: `
		@if (showSelector) {
			<custom-list-selector
				class="item-margin-right display-block"
				[itemsList]="[]"
				[placeHolderKey]="((dateRangeText$ | async) || '' | translate)?.toUpperCase()"
				[loading]="loading() || (loadingFrontLines$ | async)"
				[matTooltip]="'acm_date_range' | translate"
				[dialogComponent]="DateFilterDialogComponent"
				[dialogData]="dateRangeFilterService.dateRange"
				(dialogClosed)="selectDate($event)"
				bgColor="primary"
				inputIcon="date_range"
				inputIconSize="22"
				custom-dialog
				behaveAsButton
				hideErrorSection />
		}
	`,
	imports: [
		CommonModule,
		CustomListSelectorComponent,
		TranslateModule,
		MatTooltipModule,
		CustomDialogDirective,
	],
})
export class DateFilterSelectorComponent {
	private routeManagement: AppRouteManagementService = inject(AppRouteManagementService);
	private router: Router = inject(Router);
	private frontlinesCountService: FrontlinesCountService = inject(FrontlinesCountService);
	public dateRangeFilterService: DateRangeFilterService = inject(DateRangeFilterService);
	readonly DateFilterDialogComponent = DateFilterDialogComponent;

	loadingFrontLines$: Observable<boolean> = this.frontlinesCountService.loadingObservable$;
	dateRangeText$: Observable<string> = this.dateRangeFilterService.dateRangeTextObservable$;

	loading: Signal<boolean> = inject(SitesService).loading;
	dates: string[] = [];

	selectDate(dateRange: DateRange<Date>): void {
		const dr: DateRange<Date | null> = this.dateRangeFilterService.dateRange;
		if (dr.start == dateRange.start && dr.end == dateRange.end) return;
		this.dateRangeFilterService.dateRange = dateRange;
	}

	get showSelector(): boolean {
		const words: string[] = this.router.url.split('/').filter(w => !!w);
		const allowedPaths: string[] = [this.routeManagement.getPath('statistics')];

		return !!allowedPaths.find(path => this.isItemInUrl(words, path));
	}

	isItemInUrl(words: string[], itemUrl: string): boolean {
		for (let word of words) {
			const regex = new RegExp(`\\b${word}\\b`, 'i');
			if (regex.test(itemUrl)) return true;
		}
		return false;
	}
}
