import { Injectable, Signal, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CustomCardService {
	private readonly state$ = new BehaviorSubject<boolean>(true);

	readonly stateObservable$ = this.state$.asObservable();

	private _state = signal<boolean>(true);
	private _visibleContent = signal<boolean>(true);

	visibleContent: Signal<boolean> = this._visibleContent.asReadonly();
	state: Signal<boolean> = this._state.asReadonly();

	open(): void {
		this._state.set(true);
		if (this.state()) this.showContent();
	}

	close(): void {
		this._state.set(false);
	}

	trigger(): void {
		if (this.state()) this.close();
		else this.open();
	}

	showContent(): void {
		this._visibleContent.set(true);
	}

	hideContent(): void {
		this._visibleContent.set(false);
	}
}
