<router-outlet />

<div class="center-content">
	<custom-card class="flex-column align-start-center main" glass-effect>
		<auth-header />

		<icon name="info" size="100" />

		<div
			class="text-uppercase text-bold font-size-super-lg align-center-center item-margin-bottom">
			{{ 'acm_something_went_wrong' | translate }}
		</div>

		<custom-card class="break-spaces" overflow-unset padding-small glass-effect white-background
			>{{ 'acm_app_load_failed' | translate }}
		</custom-card>
	</custom-card>
</div>
