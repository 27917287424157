import {
	booleanAttribute,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	inject,
	Input,
	OnInit,
	Renderer2,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	selector: 'item-title',
	template: `
		@if (!loading) {
			<div class="flex-row align-start-center">
				<div
					[class.flex-row]="!suffixBellow"
					[class.flex-column]="suffixBellow"
					class="item-margin-right">
					@if (label) {
						<span [innerHtml]="label | translate"></span>
					}
					@if (suffix) {
						@if (!suffixBellow) {
							&nbsp;
						}
						<span
							[innerHtml]="suffix | translate"
							class="text-color-soft text-normal"></span>
					}
				</div>

				<ng-content></ng-content>
			</div>
		} @else {
			<div class="loading item-margin-right">
				<div class="item height large medium border-radius"></div>
			</div>
		}
	`,
	imports: [AsyncPipe, TranslateModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCardTitleComponent implements OnInit {
	private hostElement: ElementRef = inject(ElementRef);
	private renderer: Renderer2 = inject(Renderer2);

	@Input() label: string;
	@Input() suffix: string;
	@Input({ transform: booleanAttribute }) loading: boolean | null;
	@Input({ alias: 'text-font-super-large', transform: booleanAttribute })
	textFontSuperLarge: boolean;
	@Input({ alias: 'font-size-normal', transform: booleanAttribute }) textFontNormal: boolean;
	@Input({ alias: 'font-size-md', transform: booleanAttribute }) textFontMedium: boolean;
	@Input({ alias: 'remove-bold', transform: booleanAttribute }) removeBold: boolean;
	@Input({ alias: 'remove-uppercase', transform: booleanAttribute }) removeUppercase: boolean;
	@Input({ transform: booleanAttribute }) suffixBellow: boolean;

	ngOnInit(): void {
		this.renderer.addClass(this.hostElement.nativeElement, 'title');

		if (this.textFontSuperLarge)
			this.renderer.addClass(this.hostElement.nativeElement, 'font-size-super-lg');
		else if (this.textFontNormal)
			this.renderer.addClass(this.hostElement.nativeElement, 'font-size-normal');
		else if (this.textFontMedium)
			this.renderer.addClass(this.hostElement.nativeElement, 'font-size-md');
		else this.renderer.addClass(this.hostElement.nativeElement, 'font-size-lg');

		if (this.removeUppercase)
			this.renderer.addClass(this.hostElement.nativeElement, 'remove-uppercase');
		if (this.removeBold) this.renderer.addClass(this.hostElement.nativeElement, 'remove-bold');
	}
}
