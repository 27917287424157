import { GenericModel } from '@shared/models/generic.model';
import { ICountryCode, IPhoneNumber } from '@shared/interfaces';
import countryCodes from '@assets/json/country-codes.json';

export class UserPhoneNumberModel
	extends GenericModel<IPhoneNumber, string>
	implements IPhoneNumber
{
	code: string;
	number: string;

	constructor(phoneNumber?: string) {
		super(phoneNumber, UserPhoneNumberModel);

		this.code = phoneNumber ? this.getPhoneNumberCountryCode(phoneNumber) : '';
		this.number = phoneNumber?.replace(this.code, '') || '';
	}

	private getPhoneNumberCountryCode(phoneNumber: string): string {
		let codes: ICountryCode[] = [];

		for (let i = 0; i < 6; i++) {
			const codeTest: string =
				'+' + phoneNumber.replace('+', '').split('').splice(0, i).join('');
			const countryFound: ICountryCode[] = countryCodes.filter((country: ICountryCode) => {
				return country.dialCode == codeTest;
			});
			codes = codes.concat(countryFound);
		}

		return codes.length ? codes[0].dialCode : '';
	}

	get fullNumberFormatted(): string {
		return this.code && this.number ? this.code + '-' + this.number : '';
	}

	get fullNumber(): string {
		return this.code && this.number ? this.code + this.number : '';
	}
}
