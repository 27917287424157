import { ElementRef, inject, Injectable } from '@angular/core';
import { ThemeService, TTheme } from '@shared/services/theme.service';
import { BehaviorSubject } from 'rxjs';

interface IColorTheme {
	primaryBgColor: string;
	secondaryBgColor: string;
	tooltipBgColor: string;
	tooltipTextColor: string;
	splitLineColor: string;
	axisLineColor: string;
	axisLabelColor: string;
	textColor: string;
	dividerColor: string;
}

type TColorTheme = {
	[key in TTheme]: IColorTheme;
};

interface IGeneralTheme {
	fontFamily: string;
	fontSize: IFontSize;
	borderRadius: number;
	paddingTooltip: number[];
}

interface IFontSize {
	smallSuper: number;
	small: number;
	default: number;
	medium: number;
}

@Injectable({
	providedIn: 'root',
})
export class ChartOptionsService {
	private themeService: ThemeService = inject(ThemeService);

	colorsPalette = [
		'#FB8500',
		'#009988',
		'#8d260d',
		'#ad1e67',
		'#cf9fe5',
		'#1A3C40',
		'#FF5F57',
		'#FFBD2E',
		'#2a459c',
		'#29cb41',
		'#c3b300',
		'#00f4d9',
	];

	primaryColor = '#1450f5';

	colorTheme: TColorTheme = {
		'dark-theme': {
			primaryBgColor: '#202020',
			secondaryBgColor: '#2b2b2b',
			tooltipBgColor: 'rgba(97, 97, 97, 0.9)',
			tooltipTextColor: 'rgba(255, 255, 255, .96)',
			splitLineColor: 'rgba(255, 255, 255, .07)',
			axisLineColor: 'rgba(255, 255, 255, .07)',
			axisLabelColor: 'rgba(255, 255, 255, .6)',
			textColor: 'rgba(255, 255, 255, .96)',
			dividerColor: 'rgba(255, 255, 255, .4)',
		},
		'light-theme': {
			primaryBgColor: '#FFFFFF',
			secondaryBgColor: '#F1F1F1',
			tooltipBgColor: 'rgba(97, 97, 97, 0.9)',
			tooltipTextColor: 'rgba(255, 255, 255, .96)',
			splitLineColor: 'rgba(0, 0, 0, .04)',
			axisLineColor: 'rgba(0, 0, 0, .04)',
			axisLabelColor: 'rgba(0, 0, 0, 0.57)',
			textColor: 'rgba(0, 0, 0, .77)',
			dividerColor: 'rgba(0, 0, 0, 0.12)',
		},
	};

	generalTheme: IGeneralTheme = {
		fontFamily: 'KONE_FONT',
		fontSize: {
			smallSuper: 10,
			small: 12,
			default: 14,
			medium: 16,
		},
		borderRadius: 12,
		paddingTooltip: [4, 10],
	};

	gridSpace = {
		left: 0,
		right: 0,
		top: '25px',
		bottom: 0,
		containLabel: true,
	};

	private touch$ = new BehaviorSubject<boolean>(false);
	readonly touchObservable$ = this.touch$.asObservable();

	touchStart(): void {
		this.touch$.next(true);
	}

	touchEnd(): void {
		this.touch$.next(false);
	}

	hexToRGB(colorHex: string, alpha: number, useShade: boolean = false): string {
		if (!colorHex) colorHex = this.primaryColor;

		let r, g, b;
		if (colorHex.includes('rgba')) {
			const split = colorHex.split('rgba(')[1];
			const values = split.split(',');
			r = Number(values[0]);
			g = Number(values[1]);
			b = Number(values[2]);
		} else {
			colorHex = colorHex.toUpperCase();
			r = parseInt(colorHex.slice(1, 3), 16);
			g = parseInt(colorHex.slice(3, 5), 16);
			b = parseInt(colorHex.slice(5, 7), 16);
		}

		if (useShade) {
			r += 33;
			g += 33;
			b += 33;
		}

		return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
	}

	get touch(): boolean {
		return this.touch$.getValue();
	}

	get lineSeries() {
		return {
			type: 'line',
			color: this.primaryColor,
			symbolSize: 5,
			showSymbol: true,
			label: {
				show: true,
				color: this.themeColor.textColor,
			},
			data: [],
			smooth: true,
			lineStyle: {
				width: 3,
			},
			emphasis: {
				focus: 'series',
			},
		};
	}

	get barSeries() {
		return {
			type: 'bar',
			barWidth: '60%',
			barMaxWidth: 80,
			data: [],
			label: {
				show: true,
				position: 'top',
				color: this.themeColor.textColor,
				fontWeight: 'bold',
			},
		};
	}

	get pieSeries() {
		return {
			type: 'pie',
			radius: ['30%', '90%'],
			top: '15px',
			bottom: '15px',
			avoidLabelOverlap: false,
			data: [],
			label: {
				formatter: (params: any) => {
					return '{center|' + params.name + '\n' + Math.round(params.percent) + '%}';
				},
				color: this.themeColor.textColor,
				rich: {
					center: {
						fontSize: 14,
						align: 'center',
						verticalAlign: 'middle',
					},
				},
			},
			itemStyle: {
				borderRadius: '10px',
				borderColor: this.themeColor.primaryBgColor,
				borderWidth: 8,
			},
			emphasis: {
				itemStyle: {
					borderMiterLimit: 0,
					borderWidth: 1,
					borderColor: this.themeColor.dividerColor,
				},
			},
		};
	}

	get themeColor() {
		return this.colorTheme[this.themeService.theme()];
	}

	getChartHeight(cardContainer: ElementRef, headerContainer: ElementRef): number {
		const cardHeight: number = cardContainer.nativeElement.offsetHeight;
		const headerHeight: number = headerContainer.nativeElement.offsetHeight;
		const paddingTop = getComputedStyle(cardContainer.nativeElement).paddingTop.split('px')[0];
		return cardHeight - headerHeight - Number(paddingTop) * 1.8;
	}
}
