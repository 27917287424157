import {
	booleanAttribute,
	Component,
	ElementRef,
	HostBinding,
	inject,
	Input,
	Renderer2,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { ThemeService } from '@shared/services';

@Component({
	standalone: true,
	selector: 'item-highlight',
	template: `
		@if (!loading) {
			<ng-content></ng-content>
		} @else {
			<div class="loading align-center-center">
				<div class="item height medium border-radius"></div>
			</div>
		}
	`,
	styleUrls: ['./item-highlight.component.scss'],
	imports: [AsyncPipe],
})
export class ItemHighlightComponent {
	private hostElement: ElementRef = inject(ElementRef);
	private renderer: Renderer2 = inject(Renderer2);
	private themeService: ThemeService = inject(ThemeService);

	@Input({ transform: booleanAttribute }) warn: boolean | null;
	@Input({ transform: booleanAttribute }) green: boolean | null;
	@Input({ transform: booleanAttribute }) alert: boolean | null;
	@Input({ transform: booleanAttribute }) primary: boolean | null;
	@Input({ transform: booleanAttribute }) sand: boolean | null;
	@Input({ transform: booleanAttribute }) white: boolean | null;
	@Input({ alias: 'light-blue', transform: booleanAttribute }) lightBlue: boolean | null;
	@Input({ alias: 'mint-green', transform: booleanAttribute }) mintGreen: boolean | null;
	@Input({ alias: 'light-grey', transform: booleanAttribute }) lightGrey: boolean | null;

	@Input({ transform: booleanAttribute }) loading: boolean | null;
	@Input({ transform: booleanAttribute }) initialState: boolean; // closed / opened

	@HostBinding('class')
	get class() {
		if (this.loading) return {};
		return {
			'warn': this.warn,
			'green': this.green,
			'alert': this.alert,
			'primary': this.primary,
			'sand': this.sand,
			'light-blue': this.lightBlue,
			'mint-green': this.mintGreen,
			'white': this.white,
			'light-grey': this.lightGrey,
			[this.themeService.theme()]:
				!this.warn &&
				!this.green &&
				!this.alert &&
				!this.primary &&
				!this.sand &&
				!this.lightBlue &&
				!this.mintGreen &&
				!this.white &&
				!this.lightGrey,
		};
	}

	@HostBinding('style')
	get style() {
		return {
			'display': 'flex',
			'align-items': 'center',
			'place-content': 'center',
		};
	}
}
