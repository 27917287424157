import { GenericModel } from '@shared/models/generic.model';
import {
	IKenDevice,
	IKenDeviceAddress,
	IKenDeviceAddressDto,
	IKenDeviceDto,
	IKenDeviceLocation,
	IKenDeviceLocationDto,
} from '@shared/interfaces';

export class KenDeviceModel extends GenericModel<IKenDevice, IKenDeviceDto> implements IKenDevice {
	address: KenDeviceAddressModel;
	location: KenDeviceLocationModel;
	koneId: string;

	constructor(device: IKenDeviceDto | null = null) {
		super(device, KenDeviceModel);

		this.address = new KenDeviceAddressModel(device?.address);
		this.location = new KenDeviceLocationModel(device?.location);
		this.koneId = device?.thingName || '';
	}

	dataTransferObject(): IKenDeviceDto {
		const deviceDto: IKenDeviceDto = this.getOriginalData();

		deviceDto.address = this.address.dataTransferObject();
		deviceDto.location = this.location.dataTransferObject();

		return deviceDto;
	}
}

export class KenDeviceAddressModel
	extends GenericModel<IKenDeviceAddress, IKenDeviceAddressDto>
	implements IKenDeviceAddress
{
	city: string;
	countryCode: string;
	houseNumber: string;
	name: string;
	postalCode: string;
	region: string;
	street: string;

	constructor(addressDto: IKenDeviceAddressDto | null = null) {
		super(addressDto, KenDeviceAddressModel);

		this.name =
			addressDto?.name
				.filter(name => name != ' ' && name)
				.reduce((text: string, name: string) => (text += ' ' + name), '') || '';
		this.city = addressDto?.city.join(' ') || '';
		this.countryCode = addressDto?.countryCode || '';
		this.houseNumber = addressDto?.houseNumber || '';
		this.postalCode = addressDto?.postalCode || '';
		this.region = addressDto?.region || '';
		this.street = addressDto?.street || '';
	}

	dataTransferObject(): IKenDeviceAddressDto {
		return this.getOriginalData();
	}
}

export class KenDeviceLocationModel
	extends GenericModel<IKenDeviceLocation, IKenDeviceLocationDto>
	implements IKenDeviceLocation
{
	companyCode: string;
	contactPersonName: any[];
	buildingType: string;

	constructor(limits: IKenDeviceLocationDto | null = null) {
		super(limits, KenDeviceLocationModel);

		this.companyCode = limits?.companyCode || '';
		this.buildingType = limits?.buildingType || '';
		this.contactPersonName = limits?.contactPersonName || [];
	}

	dataTransferObject(): IKenDeviceLocationDto {
		return this.getOriginalData();
	}
}
