import {
	IBuildingLimits,
	IBuildingLimitsDto,
	ILimitValue,
	ILimitValueDto,
} from '@shared/interfaces';
import { GenericModel } from '@shared/models/generic.model';

export class BuildingLimitsModel
	extends GenericModel<IBuildingLimits, IBuildingLimitsDto>
	implements IBuildingLimits
{
	description: string;
	name: string;
	hard: BuildingLimitValueModel;
	soft: BuildingLimitValueModel;

	constructor(limits: IBuildingLimitsDto | null = null) {
		super(limits, BuildingLimitsModel);

		this.description = limits?.description || '';
		this.name = limits?.name || '';
		this.hard = new BuildingLimitValueModel(limits?.hard);
		this.soft = new BuildingLimitValueModel(limits?.soft);
	}

	dataTransferObject(): IBuildingLimitsDto {
		const limitsDto: IBuildingLimitsDto = this.getOriginalData();

		limitsDto.description = this.description;
		limitsDto.name = this.name;
		limitsDto.soft = this.soft.dataTransferObject();
		limitsDto.hard = this.hard.dataTransferObject();

		if (limitsDto.hasOwnProperty('default')) delete limitsDto.default;
		if (limitsDto.hasOwnProperty('lastUpdate')) delete limitsDto.lastUpdate;

		return limitsDto;
	}
}

export class BuildingLimitValueModel
	extends GenericModel<ILimitValue, ILimitValueDto>
	implements ILimitValue
{
	enabled: boolean;
	max: number;
	period: number;

	constructor(limits: ILimitValueDto | null = null) {
		super(limits, BuildingLimitValueModel);

		this.enabled = limits?.enabled || false;
		this.max = limits?.max || 1;
		this.period = limits?.period || 1;
	}

	dataTransferObject(): ILimitValueDto {
		const limitValueDto: ILimitValueDto = this.getOriginalData();

		limitValueDto.enabled = this.enabled;
		limitValueDto.max = this.max;
		limitValueDto.period = this.period;

		return limitValueDto;
	}
}
