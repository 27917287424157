<div class="date-filter-dialog page-margin">
	@if (!!tipAlert) {
		<div class="item-margin-top"></div>
	}

	<alert-balloon [message]="tipAlert" [visible]="!!tipAlert" (alertClosed)="tipAlert = ''" />

	<div class="flex-row">
		<div class="flex-column item-margin-top item-margin-right">
			<div class="font-size-super-lg text-bold align-center-center item-margin-top">
				{{ dateRange.start | date }}
			</div>
			<mat-calendar
				#startCalendar
				[selected]="dateRange"
				[maxDate]="maxDate"
				(selectedChange)="rangeDateChange($event, false)"
				class="calendar-view" />
		</div>

		<div class="flex-column item-margin-top page-margin-right">
			@if (!isToday) {
				<div class="font-size-super-lg text-bold align-center-center item-margin-top">
					{{ dateRange.end | date }}
				</div>
			}

			@if (isToday) {
				<div class="font-size-super-lg text-bold align-center-center item-margin-top">
					{{ 'acm_today' | translate }}
				</div>
			}

			<mat-calendar
				#endCalendar
				[selected]="dateRange"
				[maxDate]="maxDate"
				(selectedChange)="rangeDateChange($event, true)"
				class="calendar-view" />
		</div>
		<mat-divider vertical />
		<div class="page-margin-right"></div>
		<div class="flex-column align-start-center item-margin-top">
			@for (button of buttonFilters; track button) {
				<custom-button
					class="item-margin-bottom"
					color="primary"
					[active]="signal(rangeType == button.type)"
					[label]="button.text"
					(click)="applyDate(button.type)" />
			}

			<div class="flex flex-column align-end-center action-buttons">
				<custom-button
					class="item-margin-bottom"
					type="stroked"
					color="primary"
					label="acm_clear"
					fill
					(click)="clearFilter()" />

				<custom-button
					class="item-margin-bottom"
					type="flat"
					color="primary"
					label="acm_apply"
					fill
					(click)="applyFilter()" />
			</div>
		</div>
	</div>
</div>
