@if (menuState()) {
	<div class="image-container">
		<div class="image align-start-center">
			<img loading="lazy" src="assets/images/kone-logo-blue.png" />
		</div>
	</div>

	@for (item of menu; track item; let last = $last) {
		<a
			class="item align-center-center"
			[class.border-bottom]="last"
			[class.active]="!(loading$ | async) && (item | menuActive: router.url)"
			[class.hover]="isTablet() ? false : item.hover"
			(mouseover)="isTablet() ? null : (item.hover = true)"
			(mouseleave)="isTablet() ? null : (item.hover = false)"
			[routerLink]="item.url"
			(click)="isTablet() ? triggerMenu() : null">
			<div
				class="highlight align-center-center"
				[class.flex-column]="!isTablet()"
				[class.flex-row]="isTablet()"
				[class.tablet]="isTablet()">
				<icon
					[name]="item.icon"
					[size]="isTablet() ? 30 : 36"
					[customColor]="
						item.hover || (item | menuActive: router.url) ? 'black' : 'white'
					" />
				<div class="text" long-text-ticker>
					{{ item.tKey | translate }}
				</div>
			</div>
		</a>
	}
}

@if (isTablet()) {
	<div class="trigger-icon-outside" [class.is-open]="menuState()">
		<icon
			name="first_page"
			customColor="var(--color-text)"
			[rotate]="!menuState()"
			rotateDeg="-180"
			backButtonBorderRadius="var(--border-radius-btn)"
			backButtonBgColor="var(--color)"
			(click)="triggerMenu()" />
	</div>

	<div class="backdrop" (click)="triggerMenu()"></div>
}
