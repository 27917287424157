import { Component, inject } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { SpinnerService } from '@shared/services/spinner.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	selector: 'spinner',
	template: `
		@if ((spinner$ | async)?.show) {
			<div class="app-spinner align-center-center" @spinnerAnimation>
				<div class="bg-spinner mat-elevation-z1">
					<mat-spinner [diameter]="30" [strokeWidth]="3" color="primary"></mat-spinner>
				</div>
			</div>
		}
	`,
	styles: [
		`
			.app-spinner {
				top: 4%;
				left: 50%;
				transform: translate(-50%, 0);
				position: fixed;
				z-index: 99999;
			}

			.app-spinner .bg-spinner {
				background-color: var(--primary-layer-color);
				border-radius: 50%;
				padding: 8px;
			}
		`,
	],
	animations: [
		trigger('spinnerAnimation', [
			transition(':enter', [
				style({ transform: 'translate(-50%, -92px)' }),
				animate('.225s', style({ transform: 'translate(-50%, 0)' })),
			]),
			transition(':leave', animate('.225s', style({ transform: 'translate(-50%, -92px)' }))),
		]),
	],
	imports: [CommonModule, MatProgressSpinnerModule],
})
export class SpinnerComponent {
	private spinnerService: SpinnerService = inject(SpinnerService);

	spinner$ = this.spinnerService.spinnerObservable$;
}
