import { Directive, HostListener } from '@angular/core';

@Directive({
	standalone: true,
	selector:
		'custom-button, mat-slide-toggle, .item.button, mat-option, mat-checkbox, search-input',
})
export class ClickPropagationDirective {
	@HostListener('click', ['$event'])
	onClick(event: Event): void {
		event.stopPropagation();
	}
}
