import { Component } from '@angular/core';
import { ItemHighlightComponent } from '@shared/components/item-highlight';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	selector: 'visible-app-highlight',
	template: `
		<item-highlight class="item-margin-left" light-grey>{{ text | translate }}</item-highlight>
	`,
	imports: [ItemHighlightComponent, TranslateModule],
	styles: [``],
})
export class VisibleAppHighlightComponent {
	text: string = 'acm_visible_on_app';
}
