<div
	class="custom-list-selector"
	[class.alert]="hasError"
	[class.error-section]="!hideErrorSection">
	<!-- INPUT SELECTOR -->
	<div
		id="inputSelector"
		#inputSelector
		class="input-selector"
		[style.--input-selector-height]="inputHeight ? inputHeight : ''"
		[style.width]="inputWidth ? inputWidth : ''"
		[style.background]="bgColor | bgColorVariable"
		[class.open]="listTrigger.menuOpen"
		[class.disabled]="disable"
		[matRippleDisabled]="!!loading || disable"
		matRipple>
		@if (!loading) {
			<div class="flex-row align-start-center height-100">
				@if (inputIcon) {
					<icon
						class="input-icon left"
						[name]="inputIcon"
						[color]="inputIconColor"
						[size]="inputIconSize" />
				}
				<div class="text">
					{{
						selected
							| listSelectedTranslationPipe
								: translationKey
								: placeHolderKey
								: decodeTranslationKey
					}}
				</div>
				@if (!behaveAsButton) {
					<div class="flex"></div>
				}
				@if (!behaveAsButton && !disable) {
					<div class="input-arrow align-center-center">
						<icon
							class="input-icon"
							name="chevron_down"
							[rotate]="listTrigger.menuOpen"
							[rotateDeg]="-180"
							size="20" />
					</div>
				}
			</div>
		} @else {
			<div class="loading align-center-center height-100">
				<div class="item border-radius"></div>
			</div>
		}

		<!-- ACTION ANCHOR: USED FOR DISABLING INPUT SELECTOR AND SET CURSOR STYLE -->
		<div
			(menuClosed)="closeListSelector()"
			(menuOpened)="openListSelector()"
			class="action-anchor"
			[class.disabled]="loading || disable"
			[matMenuTriggerFor]="listSelector"
			#listTrigger="matMenuTrigger"></div>
	</div>

	@if (!hideErrorSection && hasError) {
		<div class="alert-input flex-row align-start-center" @fadeAnimation>
			<icon
				color="warn"
				class="align-center-center"
				name="info"
				size="14"
				[customColor]="'var(--button-color)'" />
			{{ alertMsg | translate }}
		</div>
	}

	<mat-menu
		#listSelector="matMenu"
		class="list-selector"
		[yPosition]="position"
		xPosition="before">
		<!-- BUTTONS FILTER -->
		@if (multiple && allowQuickSelections) {
			<div class="buttons-filter flex-row align-center-center" click-stop-propagation>
				<div class="flex-50">
					<custom-button
						color="primary"
						fill
						[label]="selectAllBtnTKey"
						(click)="selectAll()" />
				</div>
				<div class="flex-50">
					<custom-button
						color="primary"
						fill
						[label]="selectNoneBtnTKey"
						(click)="selectNone()" />
				</div>
			</div>
		}

		<!-- SEARCH FILTER -->
		@if (itemsList.length > 5) {
			<search-input
				class="search-filter"
				[class.margin]="!multiple"
				[(search)]="search"
				(searchChange)="scrollToTop()"
				[focus]="listTrigger.menuOpen"
				bgColor="secondary"
				click-stop-propagation />
		}

		<!-- LIST -->
		@if (filteredItems; as filterList) {
			<div>
				@if (filterList.length) {
					<cdk-virtual-scroll-viewport
						class="scrollable-list secondary-scroll-border"
						[itemSize]="itemSize"
						[minBufferPx]="getViewPortWidth(filterList)"
						[maxBufferPx]="getViewPortWidth(filterList)"
						[style.height]="getViewPortWidth(filterList) + 'px'"
						[style.width]="listWidth">
						<div
							class="item align-start-center"
							*cdkVirtualFor="let item of filterList"
							[class.not-multiple]="!multiple"
							[style.height]="itemSize + 'px'"
							[class.selected]="
								!multiple && isSelected(item) && !itemHighlightFromKey
							"
							[class.key-highlight]="
								getItemId(item) == getItemId(itemHighlightFromKey)
							"
							[class.disabled]="item[disableItemKey]"
							(click)="selectItem(item)"
							click-stop-propagation>
							@if (multiple) {
								<mat-checkbox [checked]="isSelected(item)" color="primary">
									<span
										[innerHTML]="
											getItemTKey(item)
												| translate
												| highlightFilteredText: search
										"></span>
								</mat-checkbox>
							}
							@if (!multiple) {
								<div
									[innerHTML]="
										getItemTKey(item)
											| translate
											| highlightFilteredText: search
									"></div>
							}
						</div>
					</cdk-virtual-scroll-viewport>
				} @else {
					<div
						class="item text-uppercase align-center-center"
						[style.height]="itemSize + 'px'">
						<icon color="primary" name="info" />
						{{ dataNotFoundTKey | translate }}
					</div>
				}
			</div>
		}
	</mat-menu>
</div>
