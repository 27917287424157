import { Directive, inject } from '@angular/core';
import { UserStoreService } from '@shared/services';

@Directive({
	standalone: true,
	selector: '[user-roles]',
	exportAs: 'userroles',
})
export class UserRolesDirective {
	private userStoreService: UserStoreService = inject(UserStoreService);

	get globalAdmin() {
		return this.userStoreService.globalAdmin;
	}

	get facilityManager() {
		return this.userStoreService.facilityManager;
	}

	get technical() {
		return this.userStoreService.technical;
	}

	get viewer() {
		return this.userStoreService.viewer;
	}
}
