import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CustomListSelectorComponent } from '@shared/components/custom-list-selector/custom-list-selector.component';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { AppRouteManagementService } from '@shared/services/app-route-management.service';
import { Router } from '@angular/router';
import { FrontlinesCountService } from '@shared/services/frontlines-count.service';
import { CountFrontlineModel } from '@shared/models/';
import { DisplayByRoleDirective } from '@shared/directives';

@Component({
	standalone: true,
	selector: 'frontlines-selector',
	template: `
		<ng-container *display-by-role="'globalAdmin, technical, viewer'">
			@if (showSelector) {
				<custom-list-selector
					class="item-margin-right display-block"
					[itemsList]="frontLines$ | async"
					[selectedItems]="selectedFrontLines$ | async"
					[loading]="loading$ | async"
					(selectedItemsChange)="selectFrontLines($event)"
					bgColor="primary"
					inputIcon="public"
					inputIconSize="22"
					translationKey="country + (companyCode)"
					multiple
					hideErrorSection />
			}
		</ng-container>
	`,
	styles: [``],
	imports: [CommonModule, TranslateModule, CustomListSelectorComponent, DisplayByRoleDirective],
})
export class FrontlinesSelectorComponent {
	private frontlinesCountService: FrontlinesCountService = inject(FrontlinesCountService);
	private routeManagement: AppRouteManagementService = inject(AppRouteManagementService);
	public router: Router = inject(Router);

	frontLines$: Observable<CountFrontlineModel[]> =
		this.frontlinesCountService.frontLinesObservable$;
	selectedFrontLines$: Observable<CountFrontlineModel[]> =
		this.frontlinesCountService.selectedFrontLinesObservable$;
	loading$: Observable<boolean> = this.frontlinesCountService.loadingObservable$;

	selectFrontLines(frontLineCount: CountFrontlineModel[]): void {
		this.frontlinesCountService.selectedFrontLines = frontLineCount;
	}

	get showSelector(): boolean {
		const words: string[] = this.router.url.split('/').filter(w => !!w);
		const allowedPaths: string[] = [this.routeManagement.getPath('statistics')];

		return !!allowedPaths.find(path => this.isItemInUrl(words, path));
	}

	isItemInUrl(words: string[], itemUrl: string): boolean {
		for (let word of words) {
			const regex = new RegExp(`\\b${word}\\b`, 'i');
			if (regex.test(itemUrl)) return true;
		}
		return false;
	}
}
