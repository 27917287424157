<div class="center-content">
	<custom-card class="flex-column main" glass-effect>
		<auth-header (goBack)="goApplication()" showBackButton />

		<div class="item-margin-top flex-column width-100">
			<div class="font-size-lg">{{ 'acm_change' | translate }}</div>
			<div class="text-uppercase font-size-super-xl">{{ 'acm_password' | translate }}</div>
		</div>

		<form
			[formGroup]="formGroup.form"
			(keydown.enter)="changePassword()"
			class="item-margin-top width-100">
			<!-- EMAIL: HIDDEN -> inform browser which account is changing password -->
			<mat-form-field appearance="outline" style="display: none">
				<mat-label>{{ 'acm_current_password' | translate }}</mat-label>
				<input
					type="type"
					autocomplete="current-email"
					matInput
					[formControlName]="formGroup.getControlName('email')" />
			</mat-form-field>

			<!-- OLD PASSWORD -->
			<mat-form-field appearance="outline" class="item-margin-top">
				<mat-label>{{ 'acm_current_password' | translate }}</mat-label>
				<input
					type="password"
					autocomplete="current-password"
					matInput
					[formControlName]="formGroup.getControlName('old')" />

				@if (formGroup.invalidValue('old')) {
					<mat-error>
						{{ 'acm_field_mandatory' | translate }}
					</mat-error>
				}
			</mat-form-field>

			<div class="item-margin-bottom item-margin-top">
				{{ 'acm_forgot_password_text' | translate }}
			</div>

			<!-- NEW PASSWORD && CONFIRMATION -->
			<password-confirmation (newPassword)="formGroup.setValue('new', $event)" />
		</form>

		<alert-balloon
			[visible]="!!alertMsg"
			[message]="alertMsg"
			(alertClosed)="alertMsg = ''"
			[color]="alertColor"
			class="width-100" />

		@if (!passwordChanged) {
			<custom-button
				class="item-margin-top"
				type="flat"
				label="acm_change_password"
				color="primary"
				fill
				[loadingInside]="loading"
				(click)="changePassword()" />
		}

		@if (passwordChanged) {
			<custom-button
				class="item-margin-top"
				type="flat"
				label="acm_go_to_application"
				color="primary"
				fill
				(click)="goApplication()" />
		}
	</custom-card>
</div>
