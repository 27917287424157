import { FormArray, FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormHelperModel } from '@shared/models/form-helper/form-helper.model';
import { FormHelperGroupModel } from '@shared/models/form-helper/form-helper-group.model';

@Injectable()
export class FormHelperArrayModel<TData> {
	private readonly formArray$: BehaviorSubject<FormArray> = new BehaviorSubject<FormArray>(
		new FormArray([new FormControl({})]),
	);
	private readonly formHelper: FormHelperModel<TData> = new FormHelperModel<TData>();
	private formBuilder: FormBuilder = new FormBuilder();

	set form(value: FormArray) {
		this.formArray$.next(value);
	}

	get form(): FormArray {
		return this.formArray$.getValue() as FormArray;
	}

	get controls() {
		return this.form.controls;
	}

	build(value: TData[]): void {
		this.form = this.formBuilder.array([]);
		if (value.length) {
			// reverse to keep original order
			const formArray = this.formHelper
				.getForm(value, undefined, { isArray: true })
				.reverse();
			this.form.controls = this.controls.concat(formArray);
		}
	}

	getRawValue(): TData[] {
		return this.form.getRawValue();
	}

	changes(): Observable<TData[]> {
		return this.form.valueChanges;
	}

	getValue<K extends keyof TData>(index: number, target: K): TData[K] {
		return this.getControl(index, target)?.value;
	}

	addControl<K extends keyof TData>(index: number, data: TData) {
		const nestedGroupForm: FormHelperGroupModel<TData> = new FormHelperGroupModel<TData>();
		nestedGroupForm.build(data);

		// Shift existing keys to higher values if necessary -> indexed value already placed
		for (let i = Object.keys(this.formHelper.nestedFormGroup).length; i >= index; i--) {
			const k: K = i as K;
			const keyReplace: K = (i + 1) as K;
			this.formHelper.nestedFormGroup[keyReplace] = this.formHelper.nestedFormGroup[k];
		}

		// add new group form with respective key
		const key: K = index as K;
		this.formHelper.nestedFormGroup[key] = nestedGroupForm;

		this.form.insert(index, nestedGroupForm.form);
	}

	removeControl<K extends keyof TData>(index: number): void {
		const keys = Object.keys(this.formHelper.nestedFormGroup);
		const keyToRemove: K = keys[index] as K;

		delete this.formHelper.nestedFormGroup[keyToRemove];
		this.controls.splice(index, 1);

		// Shift the keys after removing the entry
		for (let i = index + 1; i < keys.length; i++) {
			const previousKey: K = keys[i - 1] as K;
			const currentKey: K = keys[i] as K;
			this.formHelper.nestedFormGroup[previousKey] =
				this.formHelper.nestedFormGroup[currentKey];
		}

		// Clean up nested form by removing undefined entries
		Object.keys(this.formHelper.nestedFormGroup).forEach(key => {
			const prop = key as K;
			if (!this.formHelper.nestedFormGroup[prop])
				delete this.formHelper.nestedFormGroup[prop];
		});
	}

	setMandatoryFields(...fields: (keyof TData)[]): void {
		const keys: keyof TData = fields.join(',') as keyof TData;
		this.controls.forEach((control, index) => {
			this.getFormGroup(index).setMandatoryFields(keys);
		});
	}

	setValidators<K extends keyof TData>(
		fields: K[],
		validators: ValidatorFn | ValidatorFn[],
	): void {
		this.controls.forEach((control, index) => {
			this.getFormGroup(index).setValidators(fields, Validators.required);
		});
	}

	clearValidators(...fields: (keyof TData)[]): void {
		const keys: keyof TData = fields.join(',') as keyof TData;
		this.controls.forEach((control, index) => {
			this.getFormGroup(index).clearValidators(keys);
		});
	}

	enableFields(...fields: (keyof TData)[]): void {
		const keys: keyof TData = fields.join(',') as keyof TData;
		this.controls.forEach((control, index) => {
			this.getFormGroup(index).enableFields(keys);
		});
	}

	disableFields(...fields: (keyof TData)[]): void {
		const keys: keyof TData = fields.join(',') as keyof TData;
		this.controls.forEach((control, index) => {
			this.getFormGroup(index).disableFields(keys);
		});
	}

	getControl<K extends keyof TData>(index: number, target: K) {
		return this.getFormGroup(index).getControl(target);
	}

	getFormGroup<K extends keyof TData>(index: number): FormHelperGroupModel<TData> {
		const key: K = index as K;
		return this.formHelper.nestedFormGroup[key];
	}

	invalid(): boolean {
		return this.form.invalid;
	}

	validateForm(): void {
		this.form.markAllAsTouched();

		this.controls.forEach((control, index) => {
			this.getFormGroup(index).validateForm();
		});

		this.form.updateValueAndValidity();
	}
}
