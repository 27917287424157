import {
	ISiteAddress,
	ISiteAddressDto,
	ISiteContact,
	ISiteContactDto,
	ISite,
	ISiteDto,
	ISiteBuildingDto,
	TSiteStatus,
	ISiteBuilding,
	ISiteCallTypeDto,
	ISiteCallType,
	TSite,
} from '@shared/interfaces';
import { GenericModel } from '@shared/models/generic.model';
import { GenericBuildingModel } from '@shared/models/generic-building.model';
import { UserPhoneNumberModel } from '@shared/models/user-phone-number.model';

export class SiteModel extends GenericModel<ISite, ISiteDto> implements ISite {
	_id: string;
	apf: string;
	buildingList: SiteBuildingModel[];
	contactList: SiteContactModel[];
	address: AddressModel;
	koneId: string;
	languageCode: string;
	name: string;
	pilotSite: boolean;
	realSite: boolean;
	type: string;
	timeZone: string;
	description: string;
	companyCode: string;
	tier: string;
	status: TSiteStatus;

	constructor(siteDto: ISiteDto | null = null) {
		super(siteDto, SiteModel);

		this._id = siteDto?._id || '';
		this.apf = siteDto?.apf || '';
		this.koneId = siteDto?.koneId || '';
		this.languageCode = siteDto?.languageCode || 'en';
		this.name = siteDto?.name || '';
		this.description = siteDto?.description || '';
		this.companyCode = siteDto?.sapCompanycode || '';
		this.type = siteDto?.type || 'site';
		this.timeZone = siteDto?.timeZone || 'Europe/Helsinki';
		this.status =
			siteDto?.status === 'active' || siteDto?.status === 'inactive'
				? siteDto?.status
				: 'inactive';
		this.pilotSite = siteDto?.pilotSite || false;
		this.realSite = siteDto?.realSite || false;
		this.tier = siteDto?.tier || 'basic';
		this.address = new AddressModel(siteDto?.address, this.companyCode);
		this.buildingList = siteDto?.buildingList?.map(b => new SiteBuildingModel(b)) || [];
		this.contactList = siteDto?.contactList?.map(cl => new SiteContactModel(cl)) || [];
	}

	get siteType(): TSite {
		if (this.realSite) return 'customer';
		if (this.pilotSite) return 'pilot';
		return 'demo';
	}

	dataTransferObject(): ISiteDto {
		const siteDto: ISiteDto = this.getOriginalData();

		siteDto.apf = this.apf;
		siteDto.koneId = this.koneId;
		siteDto.languageCode = this.languageCode;
		siteDto.name = this.name;
		siteDto.type = this.type;
		siteDto.description = this.description;
		siteDto.timeZone = this.timeZone;
		siteDto.status = this.status;
		siteDto.pilotSite = this.pilotSite;
		siteDto.realSite = this.realSite;
		siteDto.tier = this.tier;
		siteDto.address = this.address.dataTransferObject();
		siteDto.buildingList = this.buildingList.map(b => b.dataTransferObject());
		siteDto.contactList = this.contactList.map(cl => cl.dataTransferObject());

		if (this.companyCode) siteDto.sapCompanycode = this.companyCode;

		return siteDto;
	}
}

export class AddressModel
	extends GenericModel<ISiteAddress, ISiteAddressDto>
	implements ISiteAddress
{
	city: string;
	country: string;
	street: string;

	private readonly companyCode: string;

	constructor(address: ISiteAddressDto | undefined, companyCode: string) {
		super(address, AddressModel);

		this.city = address?.city || '';
		this.country = address?.country || '';
		this.street = address?.street || '';
		this.companyCode = companyCode;
	}

	get fullCountry(): string {
		return this.country + (this.companyCode ? ' (' + this.companyCode + ')' : '');
	}

	dataTransferObject(): ISiteAddressDto {
		return {
			city: this.city,
			country: this.country,
			street: this.street,
		};
	}
}

export class SiteBuildingModel
	extends GenericBuildingModel<ISiteBuilding, ISiteBuildingDto>
	implements ISiteBuilding
{
	id: string;
	comment: string;
	terminal: string;
	callTypes: SiteCallTypeModel[];
	kecmAllLifts: number[];
	allowedLifts: number[];
	fullBuildingId: string;

	constructor(buildingDto: ISiteBuildingDto | null = null) {
		super(
			buildingDto,
			SiteBuildingModel,
			buildingDto?.id,
			buildingDto?.isAcs,
			buildingDto?.sfsId,
			buildingDto?.groupId,
		);

		this.id = buildingDto?.id || '';
		this.comment = buildingDto?.comment || '';
		this.terminal = buildingDto?.terminal || '';
		this.kecmAllLifts = buildingDto?.kecmAllLifts || [];
		this.allowedLifts = buildingDto?.allowedLifts || [];
		this.callTypes = buildingDto?.callTypes.map(ct => new SiteCallTypeModel(ct)) || [];

		if (buildingDto?.groupId) this.groupId = buildingDto.groupId;

		this.fullBuildingId = this.getFullBuildingId();
	}

	dataTransferObject(): ISiteBuildingDto {
		const siteBuildingDto: ISiteBuildingDto = this.getOriginalData();

		siteBuildingDto.comment = this.comment;
		siteBuildingDto.terminal = this.terminal;
		siteBuildingDto.isAcs = this.isAcs;
		siteBuildingDto.sfsId = this.sfsId;
		siteBuildingDto.allowedLifts = this.allowedLifts;
		siteBuildingDto.callTypes = this.callTypes.map(ct => ct.dataTransferObject());

		if (this.groupId) siteBuildingDto.groupId = this.groupId;

		return siteBuildingDto;
	}
}

export class SiteCallTypeModel
	extends GenericModel<ISiteCallType, ISiteCallTypeDto>
	implements ISiteCallType
{
	id: string;
	code: string;
	desc: string;
	origin?: string;

	constructor(
		callTypeDto: ISiteCallTypeDto | null = null,
		callType: ISiteCallType | null = null,
	) {
		super(callTypeDto, SiteCallTypeModel);

		this.id = callType?.id || callTypeDto?.id || '';
		this.code = callType?.code || callTypeDto?.code || '';
		this.desc = callType?.desc || callTypeDto?.desc || '';
		this.origin = callType?.origin || callTypeDto?.origin || '';

		if (callType?.origin || callTypeDto?.origin)
			this.origin = callType?.origin || callTypeDto?.origin;
	}

	dataTransferObject(): ISiteCallTypeDto {
		const siteCallTypeDto: ISiteCallTypeDto = this.getOriginalData();

		siteCallTypeDto.id = this.id;
		siteCallTypeDto.code = this.code;
		siteCallTypeDto.desc = this.desc;

		if (this.origin) siteCallTypeDto.origin = this.origin;

		return siteCallTypeDto;
	}
}

export class SiteContactModel
	extends GenericModel<ISiteContact, ISiteContactDto>
	implements ISiteContact
{
	email: string;
	type: string;
	description: string;
	firstName: string;
	lastName: string;
	showEmail?: boolean;
	phoneNumber: UserPhoneNumberModel;

	constructor(contact: ISiteContactDto | null = null) {
		super(contact, SiteContactModel);

		this.email = contact?.email || '';
		this.type = contact?.type || '';
		this.description = contact?.description || '';
		this.firstName = contact?.firstName || '';
		this.lastName = contact?.lastName || '';
		this.showEmail = contact?.showEmail || false;
		this.phoneNumber = new UserPhoneNumberModel(contact?.phoneNumber);
	}

	get fullNumberFormatted(): string {
		return this.phoneNumber.fullNumberFormatted;
	}

	dataTransferObject(): ISiteContactDto {
		const contactListDto: ISiteContactDto = this.getOriginalData();

		contactListDto.email = this.email;
		contactListDto.type = this.type;
		contactListDto.description = this.description;
		contactListDto.showEmail = this.showEmail;

		if (this.firstName) contactListDto.firstName = this.firstName;
		if (this.lastName) contactListDto.lastName = this.lastName;
		if (this.phoneNumber.fullNumber) contactListDto.phoneNumber = this.phoneNumber.fullNumber;
		else delete contactListDto.phoneNumber;

		return contactListDto;
	}
}
