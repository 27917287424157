import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { IUserData, TUserEnv } from '@shared/interfaces/user-data.interface';
import { UserDataModel, UserDataRoleModel } from '@shared/models//user-data.model';

@Injectable({
	providedIn: 'root',
})
export class UserStoreService {
	private _user: WritableSignal<UserDataModel> = signal(new UserDataModel());

	readonly user: Signal<UserDataModel> = this._user.asReadonly();
	readonly isLoggedIn: Signal<boolean> = computed(() => this.user().isLoggedIn);
	readonly environment: Signal<TUserEnv> = computed(() => this.user().environment);
	readonly role: Signal<UserDataRoleModel> = computed(() => this.user().role);
	readonly globalAdmin: Signal<boolean> = computed(() => this.role().userType == 'globalAdmin');
	readonly technical: Signal<boolean> = computed(() => this.role().userType == 'technical');
	readonly facilityManager: Signal<boolean> = computed(
		() => this.role().userType == 'facilityManager',
	);
	readonly viewer: Signal<boolean> = computed(() => this.role().userType == 'viewer');
	readonly rolesList: Signal<string[]> = computed(() => this.getRoleAsListMapped());

	updateUser(partialUser: Partial<IUserData>) {
		this._user.update(user => {
			user.update(partialUser);
			// user.role.update({
			//    admin: false,
			//    user: false,
			//    fm: false,
			//    readOnly: true
			// })
			return Object.assign(new UserDataModel(), user);
		});
	}

	getRoleAsListMapped(): string[] {
		const roles: string[] = [];

		if (this.user().role.admin) roles.push('acm_global_admin');
		if (this.user().role.user) roles.push('acm_kone_technical');
		if (this.user().role.fm) roles.push('acm_facility_manager');
		if (this.user().role.readOnly) roles.push('acm_viewer');

		return roles.length > 0 ? roles : ['N/A'];
	}

	clearUser(): void {
		this._user.set(new UserDataModel());
	}
}
