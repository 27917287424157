import {
	IFrontLineCount,
	IFrontLineCountDto,
	ICount,
	ICountDto,
	IUsers,
	IUsersDto,
} from '@shared/interfaces';
import { GenericModel } from '@shared/models/generic.model';

export class CountFrontlineModel
	extends GenericModel<IFrontLineCount, IFrontLineCountDto>
	implements IFrontLineCount
{
	companyCode: string;
	country: string;
	frontLineCountList: CountModel[];
	users: CountUsersModel;

	constructor(countFrontLine: IFrontLineCountDto | null = null) {
		super(countFrontLine, CountFrontlineModel);

		this.companyCode = countFrontLine?.companycode || '';
		this.country = countFrontLine?.country || '';
		this.frontLineCountList = countFrontLine?.frontLineCountList.map(cf => {
			return new CountModel(cf);
		}) || [new CountModel()];
		this.users = new CountUsersModel(countFrontLine?.users);
	}

	dataTransferObject(): IFrontLineCountDto {
		const frontLineCountDto: IFrontLineCountDto = this.getOriginalData();

		frontLineCountDto.companycode = this.companyCode;
		frontLineCountDto.country = this.country;
		frontLineCountDto.frontLineCountList = this.frontLineCountList.map(f => {
			return f.dataTransferObject();
		});
		frontLineCountDto.users = this.users.dataTransferObject();

		return frontLineCountDto;
	}
}

export class CountModel extends GenericModel<ICount, ICountDto> implements ICount {
	id: string;
	name: string;
	pilotSite: boolean;
	realSite: boolean;
	userCount: number;
	userRegistered: number;

	constructor(count: ICountDto | null = null) {
		super(count, CountModel);

		this.id = count?.id || '';
		this.name = count?.name || '';
		this.pilotSite = count?.pilotSite || false;
		this.realSite = count?.realSite || false;
		this.userCount = count?.userCount || 0;
		this.userRegistered = count?.userRegistered || 0;
	}

	dataTransferObject(): ICountDto {
		const countDto: ICountDto = this.getOriginalData();

		countDto.name = this.name;
		countDto.pilotSite = this.pilotSite;
		countDto.realSite = this.realSite;
		countDto.userCount = this.userCount;
		countDto.userRegistered = this.userRegistered;

		return countDto;
	}
}

export class CountUsersModel extends GenericModel<IUsers, IUsersDto> implements IUsers {
	count: number;
	countRegistered: number;

	constructor(countUsers: IUsersDto | null = null) {
		super(countUsers, CountUsersModel);

		this.count = countUsers?.count || 0;
		this.countRegistered = countUsers?.countRegistered || 0;
	}

	dataTransferObject(): IUsersDto {
		const countUsersDto: IUsersDto = this.getOriginalData();

		countUsersDto.count = this.count;
		countUsersDto.countRegistered = this.countRegistered;

		return countUsersDto;
	}
}
