import { FormGroup, Validators, ValidatorFn, AbstractControl, FormBuilder } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormHelperModel } from '@shared/models/form-helper/form-helper.model';

type TValidatorType =
	| 'email'
	| 'pattern'
	| 'minlength'
	| 'maxlength'
	| 'required'
	| 'passwordMismatch';

export type TKeyOptions<T> = {
	[K in keyof T]?: { hasModel: boolean };
};

export interface IBuildOptions {
	isArray: boolean;
}

export class FormHelperGroupModel<TData> {
	private readonly formGroup$: BehaviorSubject<FormGroup> = new BehaviorSubject<FormGroup>(
		new FormGroup({}),
	);
	private readonly formHelper: FormHelperModel<TData> = new FormHelperModel<TData>();
	private formBuilder: FormBuilder = new FormBuilder();

	set form(value: FormGroup) {
		this.formGroup$.next(value);
	}

	get form(): FormGroup {
		return this.formGroup$.getValue() as FormGroup;
	}

	get controls() {
		return this.form.controls;
	}

	build(value: TData, keyOptions?: TKeyOptions<TData>, options?: IBuildOptions): void {
		this.form = this.formBuilder.group(this.formHelper.getForm(value, keyOptions, options));
	}

	getRawValue(): TData {
		return this.form.getRawValue();
	}

	enable(): void {
		this.form.enable();
	}

	disable(): void {
		this.form.disable();
	}

	invalid(): boolean {
		return this.form.invalid;
	}

	valid(): boolean {
		return this.form.valid;
	}

	getValue<K extends keyof TData>(target: K): TData[K] {
		return this.getControl(target)?.value;
	}

	setValue<K extends keyof TData>(target: K, value: TData[K], options?: Object): void {
		this.getControl(target)?.setValue(value, options);
	}

	changes(): Observable<TData> {
		return this.form.valueChanges;
	}

	valueChanges<K extends keyof TData>(target: K): Observable<TData[K]> {
		return this.getControl(target).valueChanges;
	}

	addControl<K extends keyof TData>(target: K, control: AbstractControl) {
		const key = target as string;
		this.form.addControl(key, control);
	}

	setMandatoryFields(...fields: (keyof TData)[]): void {
		const keys: string[] = fields.map(key => String(key));
		Object.keys(this.form.controls)
			.filter(key => {
				return keys.includes(key);
			})
			.forEach(key => {
				const prop = key as keyof TData;
				this.getControl(prop)?.setValidators(Validators.required);
				this.getControl(prop)?.updateValueAndValidity();
			});
	}

	removeMandatoryFields(...fields: (keyof TData)[]): void {
		const keys: string[] = fields.map(key => String(key));
		Object.keys(this.form.controls)
			.filter(key => {
				return keys.includes(key);
			})
			.forEach(key => {
				const prop = key as keyof TData;
				this.getControl(prop)?.removeValidators(Validators.required);
				this.getControl(prop)?.updateValueAndValidity();
			});
	}

	setValidators<K extends keyof TData>(
		fields: K[],
		validators: ValidatorFn | ValidatorFn[],
	): void {
		const keys: string[] = fields.map(key => String(key));
		Object.keys(this.form.controls)
			.filter(key => {
				return keys.includes(key);
			})
			.forEach(key => {
				const prop = key as K;
				this.getControl(prop)?.addValidators(validators);
			});
	}

	hasValidator<K extends keyof TData>(field: K, validators: ValidatorFn): boolean {
		const prop = field as K;
		return this.getControl(prop)?.hasValidator(validators);
	}

	clearValidators(...fields: (keyof TData)[]): void {
		const keys: string[] = fields.map(key => String(key));
		Object.keys(this.form.controls)
			.filter(key => {
				return keys.includes(key);
			})
			.forEach(key => {
				const prop = key as keyof TData;
				this.getControl(prop)?.clearValidators();
			});
	}

	enableFields(...fields: (keyof TData)[]): void {
		const keys: string[] = fields.map(key => String(key));
		Object.keys(this.form.controls)
			.filter(key => {
				return keys.includes(key);
			})
			.forEach(key => {
				const prop = key as keyof TData;
				this.getControl(prop)?.enable();
			});
	}

	disableFields(...fields: (keyof TData)[]): void {
		const keys: string[] = fields.map(key => String(key));
		Object.keys(this.form.controls)
			.filter(key => {
				return keys.includes(key);
			})
			.forEach(key => {
				const prop = key as keyof TData;
				this.getControl(prop)?.disable();
			});
	}

	removeFields(...fields: (keyof TData)[]): void {
		const keys: string[] = fields.map(key => String(key));
		Object.keys(this.form.controls)
			.filter(key => {
				return keys.includes(key);
			})
			.forEach(key => {
				this.form.removeControl(key);
			});
	}

	invalidValue<K extends keyof TData>(target: K): boolean {
		return this.getControl(target)?.invalid || false;
	}

	validType<K extends keyof TData>(target: K, type: TValidatorType): boolean {
		return this.getControl(target)?.hasError(type) || false;
	}

	validValue<K extends keyof TData>(target: K): boolean {
		return this.getControl(target)?.valid || false;
	}

	getControl<K extends keyof TData>(target: K) {
		const key: string = target as string;
		return this.form.controls[key] || null;
	}

	getControlName<K extends keyof TData>(target: K) {
		return target;
	}

	patchValues<K extends keyof TData>(fields: K[] | 'all', data: TData): void {
		Object.keys(this.form.controls)
			.filter(key => {
				if (fields === 'all') return true;
				const prop = key as K;
				return fields.includes(prop);
			})
			.forEach(key => {
				const prop = key as K;
				this.getControl(prop)?.patchValue({
					[key]: data[prop],
				});
			});
	}

	patchValue<K extends keyof TData>(target: K, data: TData[K]): void {
		this.getControl(target)?.patchValue(data);
	}

	getNestedFormGroup<K extends keyof TData>(target: K): FormHelperGroupModel<TData[K]> {
		return this.formHelper.nestedFormGroup[target] as FormHelperGroupModel<TData[K]>;
	}

	getNestedControl<K extends keyof TData, T extends keyof TData[K]>(target: K, control: T) {
		const key = control as string;
		return this.formHelper.nestedFormGroup[target].getControl(target).get(key);
	}

	getNestedFormArray<K extends keyof TData>(target: K) {
		return this.formHelper.nestedFormArray[target] as any; // -> output: TData[K][] -> pretended: TData[K] -> solution: any
	}

	controlInvalid<K extends keyof TData>(target: K): boolean {
		return this.getControl(target)?.invalid;
	}

	validateForm(): void {
		this.form.markAllAsTouched();

		Object.keys(this.form.controls)
			.filter(key => {
				return this.form.get(key)?.errors?.['required'];
			})
			.forEach(key => {
				const prop = key as keyof TData;
				if (!this.getValue(prop)) this.form.get(key)?.setErrors(this.formHelper.errorState);
			});
	}
}
